import { Button, Flex, DatePicker, Input, Select, Space, Cascader } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { stateAndCitiesList } from '../../constants/statesNcities';
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

const options = [
    {
        "label": "Animation",
        "value": "Animation"
    },
    {
        "label": "Fashion & Style",
        "value": "Fashion & Style"
    },
    {
        "label": "People & Culture",
        "value": "People & Culture"
    },
    {
        "label": "Defence",
        "value": "Defence"
    },
    {
        "label": "Reviews",
        "value": "Reviews"
    },
    {
        "label": "Entertainment",
        "value": "Entertainment"
    },
    {
        "label": "Family & Parenting",
        "value": "Family & Parenting"
    },
    {
        "label": "Astrology",
        "value": "Astrology"
    },
    {
        "label": "Pets & Animals",
        "value": "Pets & Animals"
    },
    {
        "label": "Electronics",
        "value": "Electronics"
    },
    {
        "label": "Music",
        "value": "Music"
    },
    {
        "label": "Fitness Trainer",
        "value": "Fitness Trainer"
    },
    {
        "label": "Agriculture & Allied Sectors",
        "value": "Agriculture & Allied Sectors"
    },
    {
        "label": "Food & Drinks",
        "value": "Food & Drinks"
    },
    {
        "label": "Home & Garden",
        "value": "Home & Garden"
    },
    {
        "label": "Blogs and Travel",
        "value": "Blogs and Travel"
    },
    {
        "label": "IT & ITES",
        "value": "IT & ITES"
    },
    {
        "label": "Finance",
        "value": "Finance"
    },
    {
        "label": "Infotainment",
        "value": "Infotainment"
    },
    {
        "label": "Health & Fitness",
        "value": "Health & Fitness"
    },
    {
        "label": "Arts & Craft",
        "value": "Arts & Craft"
    },
    {
        "label": "Legal",
        "value": "Legal"
    },
    {
        "label": "Book",
        "value": "Book"
    },
    {
        "label": "Education",
        "value": "Education"
    },
    {
        "label": "Movie & Shows",
        "value": "Movie & Shows"
    },
    {
        "label": "Autos & Vehicles",
        "value": "Autos & Vehicles"
    },
    {
        "label": "Comedy",
        "value": "Comedy"
    },
    {
        "label": "Devotional",
        "value": "Devotional"
    },
    {
        "label": "Non profits",
        "value": "Non profits"
    },
    {
        "label": "Gaming",
        "value": "Gaming"
    },
    {
        "label": "Photography & Editing",
        "value": "Photography & Editing"
    },
    {
        "label": "Supernatural",
        "value": "Supernatural"
    },
    {
        "label": "Adult",
        "value": "Adult"
    },
    {
        "label": "Science & Technology",
        "value": "Science & Technology"
    },
    {
        "label": "Vloging",
        "value": "Vloging"
    },
    {
        "label": "Real Estate",
        "value": "Real Estate"
    },
    {
        "label": "Religious Content",
        "value": "Religious Content"
    },
    {
        "label": "Kids & Animation",
        "value": "Kids & Animation"
    },
    {
        "label": "Travel & Leisure",
        "value": "Travel & Leisure"
    },
    {
        "label": "Miscellaneous",
        "value": "Miscellaneous"
    },
    {
        "label": "News & Politics",
        "value": "News & Politics"
    },
    {
        "label": "Films",
        "value": "Films"
    },
    {
        "label": "Motivational",
        "value": "Motivational"
    },
    {
        "label": "Beauty",
        "value": "Beauty"
    },
    {
        "label": "Events",
        "value": "Events"
    },
    {
        "label": "Government",
        "value": "Government"
    },
    {
        "label": "DIY",
        "value": "DIY"
    },
    {
        "label": "Sports",
        "value": "Sports"
    }
]
// [
//     { "label": "Fashion and Beauty", "value": "fashion_beauty" },
//     { "label": "Health and Wellness", "value": "health_wellness" },
//     { "label": "Travel and Tourism", "value": "travel_tourism" },
//     { "label": "Food and Beverage", "value": "food_beverage" },
//     { "label": "Gaming", "value": "gaming" },
//     { "label": "Technology and Electronics", "value": "technology_electronics" },
//     { "label": "Sports and Fitness", "value": "sports_fitness" },
//     { "label": "Luxury and Lifestyle", "value": "luxury_lifestyle" },
//     { "label": "Automotive", "value": "automotive" },
//     { "label": "Entertainment and Media", "value": "entertainment_media" },
//     { "label": "Education and Learning", "value": "education_learning" },
//     { "label": "Home and Decor", "value": "home_decor" },
//     { "label": "Personal Finance and Banking", "value": "personal_finance_banking" },
//     { "label": "Retail and E-commerce", "value": "retail_ecommerce" },
//     { "label": "Beauty and Cosmetics", "value": "beauty_cosmetics" },
//     { "label": "Pharmaceuticals and Healthcare", "value": "pharmaceuticals_healthcare" },
//     { "label": "Non-profit and Charity", "value": "nonprofit_charity" },
//     { "label": "Business and Entrepreneurship", "value": "business_entrepreneurship" }
// ]

const handleChange = (value) => {
    console.log(`selected ${value}`);
};


const onChange = (value) => {
    console.log(`selected ${value}`);
};

function CreatorDetails() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [gender, setGender] = useState([]);
    const [genre, setGenre] = useState([]);
    const [size, setSize] = useState([]);
    const [location, setLocation] = useState([]);
    const [ageMin, setAgeMin] = useState(null);
    const [ageMax, setAgeMax] = useState(null);
    console.log(gender, location)
    const locationMap = new Map();

    useEffect(() => {
        const load = async () => {
            // for that id we will check if platform is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.creator_location) {
                            const arrayFromMap = [];
                            const myMap = new Map(Object.entries(currentCampaign.creator_location ?? {}));
                            myMap.forEach((values, key) => {
                                if (values.length === 0) {
                                    arrayFromMap.push([key]);
                                } else {
                                    values.forEach(value => {
                                        arrayFromMap.push([key, value]);
                                    });
                                }
                            });

                            setLocation(arrayFromMap)
                            setAgeMax(currentCampaign?.creator_max_age)
                            setAgeMin(currentCampaign?.creator_min_age)
                            setGender(currentCampaign?.creator_gender)
                            setGenre(currentCampaign?.creator_genre)
                            setSize(currentCampaign?.creator_size)
                        }
                    }
                    else {
                        // go back
                    }
                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load()
    }, []);

    const onNext = async () => {
        if (!location) {
            alert("Enter the location!");
            return;
        }

        if (ageMax > 99 || ageMin > 99) {
            alert("Age can not be more than 99!");
            return;
        }

        if (ageMax < 0 || ageMin < 0) {
            alert("Age should greater than zero!");
            return;
        }

        if (isNaN(ageMax) || isNaN(ageMin)) {
            alert("Age should be a number!");
            return;
        }

        if (ageMax < ageMin) {
            alert("Minimum age should be greater than Maximum age!");
            return;
        }

        if (!genre?.length) {
            alert("Enter the genre!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                location.forEach((item) => {
                    const [key, ...values] = item;
                    if (!locationMap.has(key)) {
                        locationMap.set(key, []);
                    }
                    locationMap.get(key).push(...values);
                });

                item.creator_location = Object.fromEntries(locationMap);
                item.creator_max_age = ageMax;
                item.creator_min_age = ageMin;
                item.creator_gender = gender;
                item.creator_genre = genre;
                item.creator_size = size;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/task');
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!location) {
            alert("Enter the location!");
            return;
        }

        if (ageMax > 99 || ageMin > 99) {
            alert("Age can not be more than 99!");
            return;
        }

        if (ageMax < 0 || ageMin < 0) {
            alert("Age should greater than zero!");
            return;
        }

        if (isNaN(ageMax) || isNaN(ageMin)) {
            alert("Age should be a number!");
            return;
        }

        if (ageMax < ageMin) {
            alert("Minimum age should be greater than Maximum age!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                location.forEach((item) => {
                    const [key, ...values] = item;
                    if (!locationMap.has(key)) {
                        locationMap.set(key, []);
                    }
                    locationMap.get(key).push(...values);
                });

                item.creator_location = Object.fromEntries(locationMap);
                item.creator_max_age = ageMax;
                item.creator_min_age = ageMin;
                item.creator_gender = gender;
                item.creator_genre = genre;
                item.creator_size = size;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/campaign/audience')
    }

    const onSelect = (e, _value) => {
        const list = [...gender]
        if (e.target.checked) {
            list.push(_value);
            setGender(list)
        }
        else {
            list.splice(gender.indexOf(_value), 1)
            setGender(list)
        }
    }

    const onSizeSelect = (e, _value) => {
        console.log(size)
        const list = [...size]

        if (e.target.checked) {
            list.push(_value);
            setSize(list)
        }
        else {
            list.splice(size.indexOf(_value), 1)
            setSize(list)
        }
    }

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title'>Enter the size of your creator</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <div className='question__checkbox-container'>
                        <input
                            checked={size?.includes("mega")}
                            type='checkbox'
                            onClick={(e) => { onSizeSelect(e, "mega") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Mega
                            </p>
                            <p className='question__checkbox-subtitle'>
                                1+ Million followers
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={size?.includes("macro")}
                            type='checkbox'
                            onClick={(e) => { onSizeSelect(e, "macro") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Macro
                            </p>
                            <p className='question__checkbox-subtitle'>
                                500K to 1 Million followers
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={size?.includes("mid")}
                            type='checkbox'
                            onClick={(e) => { onSizeSelect(e, "mid") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Mid-tier
                            </p>
                            <p className='question__checkbox-subtitle'>
                                50K to 500K followers
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={size?.includes("micro")}
                            type='checkbox'
                            onClick={(e) => { onSizeSelect(e, "micro") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Micro
                            </p>
                            <p className='question__checkbox-subtitle'>
                                10K to 50K followers
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={size?.includes("nano")}
                            type='checkbox'
                            onClick={(e) => { onSizeSelect(e, "nano") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Nano
                            </p>
                            <p className='question__checkbox-subtitle'>
                                1K to 10K followers
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={size?.includes("passionai")}
                            type='checkbox'
                            onClick={(e) => { onSizeSelect(e, "passionai") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                PassionAI
                            </p>
                            <p className='question__checkbox-subtitle'>
                                We'll use PassionAI to automatically select the best creators for you
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter the genre of your creator</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <Space
                        style={{
                            width: '100%',
                        }}
                        direction="vertical"
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            // style={{
                            //     width: '100%',
                            // }}
                            className='question__multi-select'
                            placeholder="Please select"
                            value={genre}
                            onChange={(e) => { setGenre(e) }}
                            options={options}
                        />
                    </Space>

                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter the location of your creator</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <Cascader
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => { setLocation(e) }}
                        options={stateAndCitiesList}
                        className='question__multi-select'
                        value={location}
                        multiple
                        maxTagCount="responsive"
                    />
                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter the age range of your creator</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <div style={{ display: "flex" }}>
                        <Input placeholder='Mininum' style={{ width: "100px", marginRight: "24px" }} value={ageMin} onChange={(e) => { setAgeMin(e.target.value) }} />
                        <Input placeholder='Maximum' style={{ width: "100px" }} value={ageMax} onChange={(e) => { setAgeMax(e.target.value) }} />
                    </div>

                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter the gender of your creator</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <div style={{ display: "flex" }}>
                        <div className='question__checkbox-container' style={{ marginRight: "24px" }}>
                            <input
                                checked={gender?.includes("male")}
                                type='checkbox'
                                onClick={(e) => { onSelect(e, "male") }} />
                            <div>
                                <p className='question__checkbox-title'>
                                    Male
                                </p>
                            </div>
                        </div>
                        <div className='question__checkbox-container' style={{ marginRight: "24px" }}>
                            <input
                                checked={gender?.includes("female")}
                                type='checkbox'
                                onClick={(e) => { onSelect(e, "female") }} />
                            <div>
                                <p className='question__checkbox-title'>
                                    Female
                                </p>
                            </div>
                        </div>
                        <div className='question__checkbox-container'>
                            <input
                                checked={gender?.includes("others")}
                                type='checkbox'
                                onClick={(e) => { onSelect(e, "others") }} />
                            <div>
                                <p className='question__checkbox-title'>
                                    Others
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default CreatorDetails