import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom"
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

const { TextArea } = Input;

function CreatorTask() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [value, setValue] = useState(null);

    useEffect(() => {
        const load = async () => {
            // for that id we will check if platform is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.task) {
                            setValue(currentCampaign?.task)
                        }
                    }
                    else {
                        // go back
                    }
                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load()
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter the task!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.task = value;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/script')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter the task!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.task = value;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/campaign/creator')
    }

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title question__title--required'>Task for creators</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <TextArea rows={10}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Enter your expectations from the creator. You can mention the task that he/she might have to do."
                    />
                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default CreatorTask