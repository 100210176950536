import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

function RoleInCompany() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [value, setValue] = useState("");
    const [others, setOthers] = useState("");

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.role) {
                        setValue(retrievedData?.role)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }

        }
        load()
    }, []);

    const onNext = async () => {

        // if (!value) {
        //     alert("Enter Phone Number");
        //     return;
        // }

        const updatedData = { role: value, role_others: others };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/onboard/industry')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        // if (!value) {
        //     alert("Enter Phone Number");
        //     return;
        // }

        const updatedData = { role: value, role_others: others };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/onboard/fullname')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                <h1 className='question-onboard__title'>What role do you play in this company?</h1>
                <div className='question-onboard__field'>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "founder"} type='radio' onClick={() => { setValue("founder") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Founder
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is warm, inviting, and conversational. It's like having a chat with a friend. gg. Coca-Cola and Dove
                            </p> */}
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "cmo"} type='radio' onClick={() => { setValue("cmo") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Chief Marketing Officer
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is formal, expert, and trustworthy. It's like consulting a specialist. eg. IBM and Harvard Business Review
                            </p> */}
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "imm"} type='radio' onClick={() => { setValue("imm") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Influencer Marketing Manager
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is lighthearted, humorous, and entertaining. It's like having a good time with friends. eg. Lego and Oreo
                            </p> */}
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "smm"} type='radio' onClick={() => { setValue("smm") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Social Media Marketer
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is confident, provocative, and disruptive. It's like being part of a revolution. eg. Nike and Red Bull
                            </p> */}
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "mm"} type='radio' onClick={() => { setValue("mm") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Marketing Manager
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is compassionate, understanding, and encouraging. It's like having a supportive friend. eg. Dabur and Calm
                            </p> */}
                        </div>
                    </div>
                    {/* <div className='question-onboard__checkbox-container'>
                        <input checked={value === "generic"} type='radio' onClick={() => { setValue("generic") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                I do all of the marketing for my company
                            </p>
                            <p className='question-onboard__checkbox-subtitle'>
                                This tone is compassionate, understanding, and encouraging. It's like having a supportive friend. eg. Dabur and Calm
                            </p>
                        </div>
                    </div> */}
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "sales"} type='radio' onClick={() => { setValue("sales") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Sales Manager
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is compassionate, understanding, and encouraging. It's like having a supportive friend. eg. Dabur and Calm
                            </p> */}
                        </div>
                    </div>

                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "pm"} type='radio' onClick={() => { setValue("pm") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Project/Product Manager
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is compassionate, understanding, and encouraging. It's like having a supportive friend. eg. Dabur and Calm
                            </p> */}
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "others"} type='radio' onClick={() => { setValue("others") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Others
                            </p>
                            {/* <p className='question-onboard__checkbox-subtitle'>
                                This tone is compassionate, understanding, and encouraging. It's like having a supportive friend. eg. Dabur and Calm
                            </p> */}
                        </div>
                    </div>
                    {value === "others" && <Input value={others} style={{ width: "500px" }} onChange={(e) => { setOthers(e.target.value) }} />}
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default RoleInCompany