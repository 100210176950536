import { Button, Flex, DatePicker, Input, Select, Space, Cascader } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { stateAndCitiesList } from '../../constants/statesNcities';
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

function AudienceDetails() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [location, setLocation] = useState([]);
    const [ageMin, setAgeMin] = useState(null);
    const [ageMax, setAgeMax] = useState(null);
    const [gender, setGender] = useState([]);

    const locationMap = new Map();

    useEffect(() => {
        const load = async () => {
            // for that id we will check if platform is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.audience_location) {
                            const arrayFromMap = [];
                            const myMap = new Map(Object.entries(currentCampaign.audience_location ?? {}));
                            myMap.forEach((values, key) => {
                                if (values.length === 0) {
                                    arrayFromMap.push([key]);
                                } else {
                                    values.forEach(value => {
                                        arrayFromMap.push([key, value]);
                                    });
                                }
                            });

                            setLocation(arrayFromMap)
                            setAgeMax(currentCampaign?.audience_max_age)
                            setAgeMin(currentCampaign?.audience_min_age)
                            setGender(currentCampaign?.audience_gender)
                        }
                    }
                    else {
                        // go back
                    }
                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load()
    }, []);

    const onNext = async () => {
        if (!location) {
            alert("Enter the location!");
            return;
        }

        if (ageMax > 99 || ageMin > 99) {
            alert("Age can not be more than 99!");
            return;
        }

        if (ageMax < 0 || ageMin < 0) {
            alert("Age should greater than zero!");
            return;
        }

        if (isNaN(ageMax) || isNaN(ageMin)) {
            alert("Age should be a number!");
            return;
        }

        if (ageMax < ageMin) {
            alert("Minimum age should be greater than Maximum age!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                location.forEach((item) => {
                    const [key, ...values] = item;
                    if (!locationMap.has(key)) {
                        locationMap.set(key, []);
                    }
                    locationMap.get(key).push(...values);
                });

                item.audience_location = Object.fromEntries(locationMap);
                item.audience_max_age = ageMax;
                item.audience_min_age = ageMin;
                item.audience_gender = gender;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/creator');
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!location) {
            alert("Enter the location!");
            return;
        }

        if (ageMax > 99 || ageMin > 99) {
            alert("Age can not be more than 99!");
            return;
        }

        if (ageMax < 0 || ageMin < 0) {
            alert("Age should greater than zero!");
            return;
        }

        if (isNaN(ageMax) || isNaN(ageMin)) {
            alert("Age should be a number!");
            return;
        }

        if (ageMax < ageMin) {
            alert("Minimum age should be greater than Maximum age!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                location.forEach((item) => {
                    const [key, ...values] = item;
                    if (!locationMap.has(key)) {
                        locationMap.set(key, []);
                    }
                    locationMap.get(key).push(...values);
                });

                item.audience_location = Object.fromEntries(locationMap);
                item.audience_max_age = ageMax;
                item.audience_min_age = ageMin;
                item.audience_gender = gender;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/campaign/platform')
    }

    const onSelect = (e, _value) => {
        const list = [...gender]
        if (e.target.checked) {
            list.push(_value);
            setGender(list)
        }
        else {
            list.splice(gender.indexOf(_value), 1)
            setGender(list)
        }
    }

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title'>Enter the location of your audience</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <Cascader
                        style={{
                            width: '100%',
                        }}
                        value={location}
                        options={stateAndCitiesList}
                        className='question__multi-select'
                        onChange={(e) => { setLocation(e) }}
                        multiple
                        maxTagCount="responsive"
                    />
                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter the age range of your audience</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <div style={{ display: "flex" }}>
                        <Input placeholder='Mininum' style={{ width: "100px", marginRight: "24px" }} value={ageMin} onChange={(e) => { setAgeMin(e.target.value) }} />
                        <Input placeholder='Maximum' style={{ width: "100px" }} value={ageMax} onChange={(e) => { setAgeMax(e.target.value) }} />
                    </div>
                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter the gender of your audience</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <div style={{ display: "flex" }}>
                        <div className='question__checkbox-container' style={{ marginRight: "24px" }}>
                            <input
                                checked={gender?.includes("male")}
                                type='checkbox'
                                onClick={(e) => { onSelect(e, "male") }} />
                            <div>
                                <p className='question__checkbox-title'>
                                    Male
                                </p>
                            </div>
                        </div>
                        <div className='question__checkbox-container' style={{ marginRight: "24px" }}>
                            <input
                                checked={gender?.includes("female")}
                                type='checkbox'
                                onClick={(e) => { onSelect(e, "female") }} />
                            <div>
                                <p className='question__checkbox-title'>
                                    Female
                                </p>
                            </div>
                        </div>
                        <div className='question__checkbox-container'>
                            <input
                                checked={gender?.includes("others")}
                                type='checkbox'
                                onClick={(e) => { onSelect(e, "others") }} />
                            <div>
                                <p className='question__checkbox-title'>
                                    Others
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default AudienceDetails