export const stateAndCitiesList = [
    {
        label: 'Andhra Pradesh',
        value: 'andhra_pradesh',
        children: [
            { label: 'Visakhapatnam', value: 'visakhapatnam' },
            { label: 'Vijayawada', value: 'vijayawada' },
            { label: 'Guntur', value: 'guntur' },
            { label: 'Nellore', value: 'nellore' },
            { label: 'Kurnool', value: 'kurnool' },
        ],
    },
    {
        label: 'Arunachal Pradesh',
        value: 'arunachal_pradesh',
        children: [
            { label: 'Itanagar', value: 'itanagar' },
            { label: 'Tawang', value: 'tawang' },
            { label: 'Pasighat', value: 'pasighat' },
            { label: 'Ziro', value: 'ziro' },
            { label: 'Bomdila', value: 'bomdila' },
        ],
    },
    {
        label: 'Assam',
        value: 'assam',
        children: [
            { label: 'Guwahati', value: 'guwahati' },
            { label: 'Dibrugarh', value: 'dibrugarh' },
            { label: 'Jorhat', value: 'jorhat' },
            { label: 'Silchar', value: 'silchar' },
            { label: 'Nagaon', value: 'nagaon' },
        ],
    },
    {
        label: 'Bihar',
        value: 'bihar',
        children: [
            { label: 'Patna', value: 'patna' },
            { label: 'Gaya', value: 'gaya' },
            { label: 'Bhagalpur', value: 'bhagalpur' },
            { label: 'Muzaffarpur', value: 'muzaffarpur' },
            { label: 'Purnia', value: 'purnia' },
        ],
    },
    {
        label: 'Chhattisgarh',
        value: 'chhattisgarh',
        children: [
            { label: 'Raipur', value: 'raipur' },
            { label: 'Bhilai', value: 'bhilai' },
            { label: 'Korba', value: 'korba' },
            { label: 'Bilaspur', value: 'bilaspur' },
            { label: 'Durg', value: 'durg' },
        ],
    },
    {
        label: 'Goa',
        value: 'goa',
        children: [
            { label: 'Panaji', value: 'panaji' },
            { label: 'Margao', value: 'margao' },
            { label: 'Vasco da Gama', value: 'vasco_da_gama' },
            { label: 'Mapusa', value: 'mapusa' },
            { label: 'Ponda', value: 'ponda' },
        ],
    },
    {
        label: 'Gujarat',
        value: 'gujarat',
        children: [
            { label: 'Ahmedabad', value: 'ahmedabad' },
            { label: 'Surat', value: 'surat' },
            { label: 'Vadodara', value: 'vadodara' },
            { label: 'Rajkot', value: 'rajkot' },
            { label: 'Bhavnagar', value: 'bhavnagar' },
        ],
    },
    {
        label: 'Haryana',
        value: 'haryana',
        children: [
            { label: 'Gurugram', value: 'gurugram' },
            { label: 'Faridabad', value: 'faridabad' },
            { label: 'Panipat', value: 'panipat' },
            { label: 'Ambala', value: 'ambala' },
            { label: 'Hisar', value: 'hisar' },
        ],
    },
    {
        label: 'Himachal Pradesh',
        value: 'himachal_pradesh',
        children: [
            { label: 'Shimla', value: 'shimla' },
            { label: 'Manali', value: 'manali' },
            { label: 'Dharamshala', value: 'dharamshala' },
            { label: 'Solan', value: 'solan' },
            { label: 'Mandi', value: 'mandi' },
        ],
    },
    {
        label: 'Jharkhand',
        value: 'jharkhand',
        children: [
            { label: 'Ranchi', value: 'ranchi' },
            { label: 'Jamshedpur', value: 'jamshedpur' },
            { label: 'Dhanbad', value: 'dhanbad' },
            { label: 'Bokaro', value: 'bokaro' },
            { label: 'Deoghar', value: 'deoghar' },
        ],
    },
    {
        label: 'Karnataka',
        value: 'karnataka',
        children: [
            { label: 'Bengaluru', value: 'bengaluru' },
            { label: 'Mysuru', value: 'mysuru' },
            { label: 'Mangaluru', value: 'mangaluru' },
            { label: 'Hubballi', value: 'hubballi' },
            { label: 'Belagavi', value: 'belagavi' },
        ],
    },
    {
        label: 'Kerala',
        value: 'kerala',
        children: [
            { label: 'Thiruvananthapuram', value: 'thiruvananthapuram' },
            { label: 'Kochi', value: 'kochi' },
            { label: 'Kozhikode', value: 'kozhikode' },
            { label: 'Thrissur', value: 'thrissur' },
            { label: 'Kannur', value: 'kannur' },
        ],
    },
    {
        label: 'Madhya Pradesh',
        value: 'madhya_pradesh',
        children: [
            { label: 'Bhopal', value: 'bhopal' },
            { label: 'Indore', value: 'indore' },
            { label: 'Gwalior', value: 'gwalior' },
            { label: 'Jabalpur', value: 'jabalpur' },
            { label: 'Ujjain', value: 'ujjain' },
        ],
    },
    {
        label: 'Maharashtra',
        value: 'maharashtra',
        children: [
            { label: 'Mumbai', value: 'mumbai' },
            { label: 'Pune', value: 'pune' },
            { label: 'Nagpur', value: 'nagpur' },
            { label: 'Nashik', value: 'nashik' },
            { label: 'Aurangabad', value: 'aurangabad' },
        ],
    },
    {
        label: 'Manipur',
        value: 'manipur',
        children: [
            { label: 'Imphal', value: 'imphal' },
            { label: 'Thoubal', value: 'thoubal' },
            { label: 'Bishnupur', value: 'bishnupur' },
            { label: 'Churachandpur', value: 'churachandpur' },
            { label: 'Ukhrul', value: 'ukhrul' },
        ],
    },
    {
        label: 'Meghalaya',
        value: 'meghalaya',
        children: [
            { label: 'Shillong', value: 'shillong' },
            { label: 'Tura', value: 'tura' },
            { label: 'Jowai', value: 'jowai' },
            { label: 'Nongpoh', value: 'nongpoh' },
            { label: 'Baghmara', value: 'baghmara' },
        ],
    },
    {
        label: 'Mizoram',
        value: 'mizoram',
        children: [
            { label: 'Aizawl', value: 'aizawl' },
            { label: 'Lunglei', value: 'lunglei' },
            { label: 'Saiha', value: 'saiha' },
            { label: 'Champhai', value: 'champhai' },
            { label: 'Serchhip', value: 'serchhip' },
        ],
    },
    {
        label: 'Nagaland',
        value: 'nagaland',
        children: [
            { label: 'Kohima', value: 'kohima' },
            { label: 'Dimapur', value: 'dimapur' },
            { label: 'Mokokchung', value: 'mokokchung' },
            { label: 'Tuensang', value: 'tuensang' },
            { label: 'Wokha', value: 'wokha' },
        ],
    },
    {
        label: 'Odisha',
        value: 'odisha',
        children: [
            { label: 'Bhubaneswar', value: 'bhubaneswar' },
            { label: 'Cuttack', value: 'cuttack' },
            { label: 'Rourkela', value: 'rourkela' },
            { label: 'Berhampur', value: 'berhampur' },
            { label: 'Sambalpur', value: 'sambalpur' },
        ],
    },
    {
        label: 'Punjab',
        value: 'punjab',
        children: [
            { label: 'Ludhiana', value: 'ludhiana' },
            { label: 'Amritsar', value: 'amritsar' },
            { label: 'Jalandhar', value: 'jalandhar' },
            { label: 'Patiala', value: 'patiala' },
            { label: 'Bathinda', value: 'bathinda' },
        ],
    },
    {
        label: 'Rajasthan',
        value: 'rajasthan',
        children: [
            { label: 'Jaipur', value: 'jaipur' },
            { label: 'Jodhpur', value: 'jodhpur' },
            { label: 'Udaipur', value: 'udaipur' },
            { label: 'Kota', value: 'kota' },
            { label: 'Ajmer', value: 'ajmer' },
        ],
    },
    {
        label: 'Sikkim',
        value: 'sikkim',
        children: [
            { label: 'Gangtok', value: 'gangtok' },
            { label: 'Geyzing', value: 'geyzing' },
            { label: 'Namchi', value: 'namchi' },
            { label: 'Mangan', value: 'mangan' },
            { label: 'Pelling', value: 'pelling' },
        ],
    },
    {
        label: 'Tamil Nadu',
        value: 'tamil_nadu',
        children: [
            { label: 'Chennai', value: 'chennai' },
            { label: 'Coimbatore', value: 'coimbatore' },
            { label: 'Madurai', value: 'madurai' },
            { label: 'Tiruchirappalli', value: 'tiruchirappalli' },
            { label: 'Salem', value: 'salem' },
        ],
    },
    {
        label: 'Telangana',
        value: 'telangana',
        children: [
            { label: 'Hyderabad', value: 'hyderabad' },
            { label: 'Warangal', value: 'warangal' },
            { label: 'Nizamabad', value: 'nizamabad' },
            { label: 'Khammam', value: 'khammam' },
            { label: 'Karimnagar', value: 'karimnagar' },
        ],
    },
    {
        label: 'Tripura',
        value: 'tripura',
        children: [
            { label: 'Agartala', value: 'agartala' },
            { label: 'Udaipur', value: 'udaipur' },
            { label: 'Dharmanagar', value: 'dharmanagar' },
            { label: 'Kailashahar', value: 'kailashahar' },
            { label: 'Belonia', value: 'belonia' },
        ],
    },
    {
        label: 'Uttar Pradesh',
        value: 'uttar_pradesh',
        children: [
            { label: 'Lucknow', value: 'lucknow' },
            { label: 'Kanpur', value: 'kanpur' },
            { label: 'Ghaziabad', value: 'ghaziabad' },
            { label: 'Agra', value: 'agra' },
            { label: 'Varanasi', value: 'varanasi' },
        ],
    },
    {
        label: 'Uttarakhand',
        value: 'uttarakhand',
        children: [
            { label: 'Dehradun', value: 'dehradun' },
            { label: 'Haridwar', value: 'haridwar' },
            { label: 'Roorkee', value: 'roorkee' },
            { label: 'Haldwani', value: 'haldwani' },
            { label: 'Rishikesh', value: 'rishikesh' },
        ],
    },
    {
        label: 'West Bengal',
        value: 'west_bengal',
        children: [
            { label: 'Kolkata', value: 'kolkata' },
            { label: 'Howrah', value: 'howrah' },
            { label: 'Darjeeling', value: 'darjeeling' },
            { label: 'Siliguri', value: 'siliguri' },
            { label: 'Durgapur', value: 'durgapur' },
        ],
    },
    {
        label: 'Andaman and Nicobar Islands',
        value: 'andaman_nicobar',
        children: [
            { label: 'Port Blair', value: 'port_blair' },
            { label: 'Diglipur', value: 'diglipur' },
            { label: 'Rangat', value: 'rangat' },
            { label: 'Mayabunder', value: 'mayabunder' },
            { label: 'Car Nicobar', value: 'car_nicobar' },
        ],
    },
    {
        label: 'Chandigarh',
        value: 'chandigarh',
        children: [
            { label: 'Chandigarh', value: 'chandigarh' },
            { label: 'Manimajra', value: 'manimajra' },
            { label: 'Dhanas', value: 'dhanas' },
            { label: 'Mullanpur', value: 'mullanpur' },
            { label: 'Raipur Khurd', value: 'raipur_khurd' },
        ],
    },
    {
        label: 'Dadra and Nagar Haveli and Daman and Diu',
        value: 'dadra_nagar_haveli_daman_diu',
        children: [
            { label: 'Daman', value: 'daman' },
            { label: 'Diu', value: 'diu' },
            { label: 'Silvassa', value: 'silvassa' },
            { label: 'Amli', value: 'amli' },
            { label: 'Kachigam', value: 'kachigam' },
        ],
    },
    {
        label: 'Lakshadweep',
        value: 'lakshadweep',
        children: [
            { label: 'Kavaratti', value: 'kavaratti' },
            { label: 'Agatti', value: 'agatti' },
            { label: 'Amini', value: 'amini' },
            { label: 'Andrott', value: 'andrott' },
            { label: 'Minicoy', value: 'minicoy' },
        ],
    },
    {
        label: 'Delhi',
        value: 'delhi',
        children: [
            { label: 'New Delhi', value: 'new_delhi' },
            { label: 'Central Delhi', value: 'central_delhi' },
            { label: 'North Delhi', value: 'north_delhi' },
            { label: 'South Delhi', value: 'south_delhi' },
            { label: 'West Delhi', value: 'west_delhi' },
        ],
    },
    {
        label: 'Puducherry',
        value: 'puducherry',
        children: [
            { label: 'Puducherry', value: 'puducherry' },
            { label: 'Karaikal', value: 'karaikal' },
            { label: 'Yanam', value: 'yanam' },
            { label: 'Mahe', value: 'mahe' },
            { label: 'Oulgaret', value: 'oulgaret' },
        ],
    },
    {
        label: 'Jammu and Kashmir',
        value: 'jammu_kashmir',
        children: [
            { label: 'Srinagar', value: 'srinagar' },
            { label: 'Jammu', value: 'jammu' },
            { label: 'Anantnag', value: 'anantnag' },
            { label: 'Baramulla', value: 'baramulla' },
            { label: 'Udhampur', value: 'udhampur' },
        ],
    },
    {
        label: 'Ladakh',
        value: 'ladakh',
        children: [
            { label: 'Leh', value: 'leh' },
            { label: 'Kargil', value: 'kargil' },
            { label: 'Nubra', value: 'nubra' },
            { label: 'Zanskar', value: 'zanskar' },
            { label: 'Dras', value: 'dras' },
        ],
    },
];