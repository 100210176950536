import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Typography } from '@mui/material';
import { Button, Input } from 'antd';

// const analyticsData = [
//     { title: 'Influencers', value: '' },
//     { title: '5', value: '' },
//     { title: 'Engagement Rate', value: '4.5%' },
//     { title: 'Average Views', value: '' },
//     { title: 'Shares', value: '5,000' },
//     { title: 'Comments', value: '1,200' },
//     { title: 'Average Reach', value: '' },
//     { title: 'Mentions', value: '320' },
//     { title: 'Clicks', value: '10,500' },
//     // { title: 'Reach', value: '300,000' },
//     // { title: 'Posts', value: '120' },
//     // { title: 'Stories', value: '80' },
// ];

const CountModal = ({ open, handleClose, customCount, setCustomCount, onCustomClick, onDefaultClick, defaultCount }) => {


    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" >
                <DialogTitle><b>Edit Number Of Influencers</b></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Influencers #
                            </Typography>
                            <Typography variant="body1">45,000</Typography>
                        </Grid> */}
                        <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                                {(defaultCount / 1.5) || "-"}
                            </Typography>
                            {/* <Typography variant="body1">12,500</Typography> */}
                        </Grid>
                        <Grid item xs={6}>
                            <Input style={{ width: "100px" }} type='number' value={customCount} onChange={(e) => setCustomCount(e.target.value)} />
                            {/* <Typography variant="body1">4.5%</Typography> */}
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Average Views
                            </Typography>
                            <Typography variant="body1">200,000</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Shares
                            </Typography>
                            <Typography variant="body1">5,000</Typography>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Comments
                            </Typography>
                            <Typography variant="body1">1,200</Typography>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Average Reach
                            </Typography>
                            <Typography variant="body1">8,500</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Mentions
                            </Typography>
                            <Typography variant="body1">320</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                                Clicks
                            </Typography>
                            <Typography variant="body1">10,500</Typography>
                        </Grid> */}
                        {/* <Grid item xs={6}> */}
                            {/* <Typography variant="h6" gutterBottom>
                                Reach
                            </Typography>
                            <Typography variant="body1">300,000</Typography> */}
                        {/* </Grid> */}
                        <Grid item xs={6}>
                            <Button size='large' className='question-onboard__prv-btn' onClick={onDefaultClick}>Apply</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size='large' className='question-onboard__prv-btn' onClick={onCustomClick}>Apply</Button>
                            {/* <Typography variant="h6" gutterBottom>
                                Stories
                            </Typography>
                            <Typography variant="body1">80</Typography> */}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CountModal;
