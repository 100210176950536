import React, { useEffect, useState } from 'react';
// import { DownOutlined, UserOutlined } from '@ant-design/icons';
import SocialMediaBox from './SocialMediaBox';
import { useNavigate } from "react-router-dom"
import { DownOutlined, YoutubeFilled, LinkedinFilled, InstagramFilled, FacebookFilled } from '@ant-design/icons';
import { Flex, Dropdown, Input, Space, Button } from 'antd';
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';


function SocialMedia() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [insta, setInsta] = useState(null);
    const [youtube, setYoutube] = useState(null);

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.social_insta) {
                        setInsta(retrievedData?.social_insta)
                    }
                    if (retrievedData?.social_yt) {
                        setYoutube(retrievedData?.social_yt)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }
        }
        load()
    }, []);

    const onNext = async () => {

        if (youtube?.trim().length > 100 || insta?.trim().length > 100) {
            alert("This should not be more than 100 characters");
            return;
        }

        const updatedData = { social_insta: insta, social_yt: youtube };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/onboard/tone')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (youtube?.trim().length > 100 || insta?.trim().length > 100) {
            alert("This should not be more than 100 characters");
            return;
        }

        const updatedData = { social_insta: insta, social_yt: youtube };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    // const onNext = () => {
    //     navigate('/onboard/tone')
    // }

    const onPrevious = () => {
        navigate('/onboard/keywords')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                <h1 className='question-onboard__title'>Add your social media handles</h1>
                <Flex className='question__field' vertical={false} align='center'>
                    <div style={{ width: "50px", marginRight: "8px", fontSize: "xxx-large" }}>
                        {/* <InstagramFilled /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#d62495" viewBox="0 0 256 256"><path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"></path></svg>
                    </div>
                    <Input placeholder="" value={insta}
                        onChange={(e) => setInsta(e.target.value)}
                        className='social-media__input'
                        style={{ width: "250px", marginRight: "8px" }}
                    />
                </Flex>
                <Flex className='question__field' vertical={false} align='center'>
                    <div style={{ width: "50px", marginRight: "8px", fontSize: "xxx-large" }}>
                        {/* <YoutubeFilled /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#ed2c2c" viewBox="0 0 256 256"><path d="M164.44,121.34l-48-32A8,8,0,0,0,104,96v64a8,8,0,0,0,12.44,6.66l48-32a8,8,0,0,0,0-13.32ZM120,145.05V111l25.58,17ZM234.33,69.52a24,24,0,0,0-14.49-16.4C185.56,39.88,131,40,128,40s-57.56-.12-91.84,13.12a24,24,0,0,0-14.49,16.4C19.08,79.5,16,97.74,16,128s3.08,48.5,5.67,58.48a24,24,0,0,0,14.49,16.41C69,215.56,120.4,216,127.34,216h1.32c6.94,0,58.37-.44,91.18-13.11a24,24,0,0,0,14.49-16.41c2.59-10,5.67-28.22,5.67-58.48S236.92,79.5,234.33,69.52Zm-15.49,113a8,8,0,0,1-4.77,5.49c-31.65,12.22-85.48,12-86,12H128c-.54,0-54.33.2-86-12a8,8,0,0,1-4.77-5.49C34.8,173.39,32,156.57,32,128s2.8-45.39,5.16-54.47A8,8,0,0,1,41.93,68c30.52-11.79,81.66-12,85.85-12h.27c.54,0,54.38-.18,86,12a8,8,0,0,1,4.77,5.49C221.2,82.61,224,99.43,224,128S221.2,173.39,218.84,182.47Z"></path></svg>
                    </div>
                    <Input placeholder="" value={youtube}
                        onChange={(e) => setYoutube(e.target.value)}
                        className='social-media__input'
                        style={{ width: "250px", marginRight: "8px" }}
                    />
                </Flex>
                {/* <SocialMediaBox />
                <SocialMediaBox /> */}
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default SocialMedia