import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Objective from '../../components/Campaign/Objective';
import Budget from '../../components/Campaign/Budget';
import TimeLine from '../../components/Campaign/TimeLine';
import Platform from '../../components/Campaign/Platform';
import AudienceDetails from '../../components/Campaign/AudienceDetails';
import CreatorDetails from '../../components/Campaign/CreatorDetails';
import CreatorTask from '../../components/Campaign/CreatorTask';
import CreatorScript from '../../components/Campaign/CreatorScript';
import CreatorContentCaption from '../../components/Campaign/CreatorContentCaption';
import { MagnifyingGlass, Bell, SignOut, MetaLogo } from "@phosphor-icons/react";
import { getData } from '../../utils/saveData';

function BrandCampaign() {
    let collectionName;
    const navigate = useNavigate()
    const { id } = useParams();
    const [companyDetails, setCompanyDetails] = useState({})

    useEffect(() => {
        if (localStorage.getItem("uname")) {
            collectionName = localStorage.getItem("uname");
            getdata()
        }

    }, [])

    const getdata = async () => {
        const brandData = await getData(collectionName, "brand_details");
        setCompanyDetails(brandData)
    }

    return (
        // <Flex vertical={false} className='onboard__container'>
        //     <div className='onboard__panel'>
        //         <PassionPanel />
        //     </div>
        //     <div className='onboard__main'>
        //         <h1 className='onboard__campaign-name'>OYO001</h1>
        //         <div className='onboard__box'>
        <div className='container' style={{ "maxWidth": "100%" }}>
            <div class="app">
                <header class="app-header">
                    <div class="app-header-logo">
                        <div class="logo">
                            {/* <span class="logo-icon"> */}
                            {/* <Orange size={32} color="#d62424" weight="duotone" /> */}

                            {/* <MetaLogo size={32} color="#245fd6" weight="bold" />
                                <img src="src/assets/images/nike_logo.png" /> */}
                            {/* </span> */}
                            <h1 class="logo-title">
                                <span>{companyDetails?.company_name ?? ""}</span>
                                {/* <span></span> */}
                            </h1>
                        </div>
                    </div>
                    <div class="app-header-navigation">
                        <div class="tabs">
                            {/* <a href="#" class="active">
                                India
                            </a>
                            <a href="#" >
                                USA
                            </a>
                            <a href="#">
                                Cards
                            </a>
                            <a href="#">

                            </a>
                            <a href="#">

                            </a>
                            <a href="#">

                            </a> */}
                        </div>
                    </div>
                    <div class="app-header-actions">
                        <button class="user-profile">
                            <span>{companyDetails?.full_name ?? ""}</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#245fd6" viewBox="0 0 256 256"><path d="M160,96H96a52,52,0,0,0,0,104h64a52,52,0,0,0,0-104Zm0,80H96a28,28,0,0,1,0-56h64a28,28,0,0,1,0,56Zm-48-28a16,16,0,1,1-16-16A16,16,0,0,1,112,148Zm64,0a16,16,0,1,1-16-16A16,16,0,0,1,176,148ZM212,36a36,36,0,0,0-33.94,24H77.94A36,36,0,0,0,8,72v76a88.1,88.1,0,0,0,88,88h64a88.1,88.1,0,0,0,88-88V72A36,36,0,0,0,212,36Zm12,112a64.07,64.07,0,0,1-64,64H96a64.07,64.07,0,0,1-64-64V72a12,12,0,0,1,24,0A12,12,0,0,0,68,84H188a12,12,0,0,0,12-12,12,12,0,0,1,24,0Z"></path></svg>
                                {/* <img src="https:assets.codepen.io/285131/almeria-avatar.jpeg" /> */}
                            </span>
                        </button>
                        <div class="app-header-actions-buttons">
                            <button class="icon-button large">
                                <MagnifyingGlass />
                                {/* <i class="ph-magnifying-glass"></i> */}
                            </button>
                            <button class="icon-button large">
                                <Bell color="white" weight="duotone" />
                                {/* <i class="ph-bell"></i> */}
                            </button>
                            <button class="icon-button large">
                                <SignOut size={32} onClick={() => { localStorage.removeItem("uname"); navigate("/") }} />
                                {/* <i class="ph-bell"></i> */}
                            </button>
                        </div>
                    </div>
                    <div class="app-header-mobile">
                        <button class="icon-button large">
                            <i class="ph-list"></i>
                        </button>
                    </div>

                </header>
                <div class="app-body">
                    <div class="app-body-navigation">
                        <nav class="navigation">
                            <Link to={"/home"}>
                                <i class="ph-browsers"></i>
                                <span>Home</span>
                            </Link>
                            {/* <a href="#">
                                <i class="ph-check-square"></i>
                                <span>Reports</span>
                            </a> */}
                            <Link>
                                <i class="ph-swap"></i>
                                <span>Create Campaign</span>
                            </Link>
                            <Link to={"/onboard/companyname"}>
                                <i class="ph-swap"></i>
                                <span>Edit Brand Details</span>
                            </Link>
                            {/* <a href="#">
                                <i class="ph-file-text"></i>
                                <span>Influencer Search</span>
                            </a>
                            <a href="#">
                                <i class="ph-globe"></i>
                                <span>Campaign Analytics</span>
                            </a>
                            <a href="#">
                                <i class="ph-clipboard-text"></i>
                                <span>Settings</span>
                            </a>
                            <a href="#">
                                <i class="ph-clipboard-text"></i>
                                <span>Account</span>
                            </a> */}
                        </nav>
                        <footer class="footer">
                            <h1>PassionAI<small>©</small></h1>
                            <div>
                                Beefluence ©<br />
                                All Rights Reserved 2024
                            </div>
                        </footer>
                    </div>
                    <div class="app-body-main-content">
                        {(() => {
                            switch (id) {
                                case 'objective':
                                    return <Objective />;
                                case 'budget':
                                    return <Budget />;
                                case 'timeline':
                                    return <TimeLine />;
                                case 'platform':
                                    return <Platform />;
                                case 'audience':
                                    return <AudienceDetails />;
                                case 'creator':
                                    return <CreatorDetails />;
                                case 'task':
                                    return <CreatorTask />;
                                case 'script':
                                    return <CreatorScript />;
                                case 'caption':
                                    return <CreatorContentCaption />;
                                default:
                                    return <div>Error 404</div>;
                            }
                        })()}
                    </div>

                </div>
            </div>
        </div>
        //         </div>

        //     </div>
        // </Flex>
    )
}

export default BrandCampaign