import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';
import { url } from '../../constants/regex';

function BrandWebsite() {
    const navigate = useNavigate();
    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [value, setValue] = useState("");

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.website) {
                        setValue(retrievedData?.website)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }
        }
        load();
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter Website");
            return;
        }
        const regex = new RegExp(url);
        if (!value.match(regex)) {
            window.alert("URL seems incorrect!");
            return;
        }

        const updatedData = { website: value };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/onboard/industry')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter Website");
            return;
        }
        const regex = new RegExp(url);
        if (!value.match(regex)) {
            window.alert("URL seems incorrect!");
            return;
        }

        const updatedData = { website: value };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/onboard/role')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                <h1 className='question-onboard__title'>Enter your website link</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question-onboard__field'>
                    <Input placeholder="www.passionai.com" value={value} onChange={(e) => setValue(e.target.value)} />
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default BrandWebsite