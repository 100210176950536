import React, { useEffect, useState } from 'react'
import { Select, Space, Button, Flex } from 'antd';
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

const options = [
    {
        "label": "Animation",
        "value": "Animation"
    },
    {
        "label": "Fashion & Style",
        "value": "Fashion & Style"
    },
    {
        "label": "People & Culture",
        "value": "People & Culture"
    },
    {
        "label": "Defence",
        "value": "Defence"
    },
    {
        "label": "Reviews",
        "value": "Reviews"
    },
    {
        "label": "Entertainment",
        "value": "Entertainment"
    },
    {
        "label": "Family & Parenting",
        "value": "Family & Parenting"
    },
    {
        "label": "Astrology",
        "value": "Astrology"
    },
    {
        "label": "Pets & Animals",
        "value": "Pets & Animals"
    },
    {
        "label": "Electronics",
        "value": "Electronics"
    },
    {
        "label": "Music",
        "value": "Music"
    },
    {
        "label": "Fitness Trainer",
        "value": "Fitness Trainer"
    },
    {
        "label": "Agriculture & Allied Sectors",
        "value": "Agriculture & Allied Sectors"
    },
    {
        "label": "Food & Drinks",
        "value": "Food & Drinks"
    },
    {
        "label": "Home & Garden",
        "value": "Home & Garden"
    },
    {
        "label": "Blogs and Travel",
        "value": "Blogs and Travel"
    },
    {
        "label": "IT & ITES",
        "value": "IT & ITES"
    },
    {
        "label": "Finance",
        "value": "Finance"
    },
    {
        "label": "Infotainment",
        "value": "Infotainment"
    },
    {
        "label": "Health & Fitness",
        "value": "Health & Fitness"
    },
    {
        "label": "Arts & Craft",
        "value": "Arts & Craft"
    },
    {
        "label": "Legal",
        "value": "Legal"
    },
    {
        "label": "Book",
        "value": "Book"
    },
    {
        "label": "Education",
        "value": "Education"
    },
    {
        "label": "Movie & Shows",
        "value": "Movie & Shows"
    },
    {
        "label": "Autos & Vehicles",
        "value": "Autos & Vehicles"
    },
    {
        "label": "Comedy",
        "value": "Comedy"
    },
    {
        "label": "Devotional",
        "value": "Devotional"
    },
    {
        "label": "Non profits",
        "value": "Non profits"
    },
    {
        "label": "Gaming",
        "value": "Gaming"
    },
    {
        "label": "Photography & Editing",
        "value": "Photography & Editing"
    },
    {
        "label": "Supernatural",
        "value": "Supernatural"
    },
    {
        "label": "Adult",
        "value": "Adult"
    },
    {
        "label": "Science & Technology",
        "value": "Science & Technology"
    },
    {
        "label": "Vloging",
        "value": "Vloging"
    },
    {
        "label": "Real Estate",
        "value": "Real Estate"
    },
    {
        "label": "Religious Content",
        "value": "Religious Content"
    },
    {
        "label": "Kids & Animation",
        "value": "Kids & Animation"
    },
    {
        "label": "Travel & Leisure",
        "value": "Travel & Leisure"
    },
    {
        "label": "Miscellaneous",
        "value": "Miscellaneous"
    },
    {
        "label": "News & Politics",
        "value": "News & Politics"
    },
    {
        "label": "Films",
        "value": "Films"
    },
    {
        "label": "Motivational",
        "value": "Motivational"
    },
    {
        "label": "Beauty",
        "value": "Beauty"
    },
    {
        "label": "Events",
        "value": "Events"
    },
    {
        "label": "Government",
        "value": "Government"
    },
    {
        "label": "DIY",
        "value": "DIY"
    },
    {
        "label": "Sports",
        "value": "Sports"
    }
]
// [
//     { "label": "Fashion and Beauty", "value": "fashion_beauty" },
//     { "label": "Health and Wellness", "value": "health_wellness" },
//     { "label": "Travel and Tourism", "value": "travel_tourism" },
//     { "label": "Food and Beverage", "value": "food_beverage" },
//     { "label": "Gaming", "value": "gaming" },
//     { "label": "Technology and Electronics", "value": "technology_electronics" },
//     { "label": "Sports and Fitness", "value": "sports_fitness" },
//     { "label": "Luxury and Lifestyle", "value": "luxury_lifestyle" },
//     { "label": "Automotive", "value": "automotive" },
//     { "label": "Entertainment and Media", "value": "entertainment_media" },
//     { "label": "Education and Learning", "value": "education_learning" },
//     { "label": "Home and Decor", "value": "home_decor" },
//     { "label": "Personal Finance and Banking", "value": "personal_finance_banking" },
//     { "label": "Retail and E-commerce", "value": "retail_ecommerce" },
//     { "label": "Beauty and Cosmetics", "value": "beauty_cosmetics" },
//     { "label": "Pharmaceuticals and Healthcare", "value": "pharmaceuticals_healthcare" },
//     { "label": "Non-profit and Charity", "value": "nonprofit_charity" },
//     { "label": "Business and Entrepreneurship", "value": "business_entrepreneurship" }
// ]

function Industry() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [value, setValue] = useState(null);

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.industry) {
                        setValue(retrievedData?.industry)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }
        }
        load();
    }, []);

    const onNext = async () => {

        if (value?.length < 1) {
            alert("Enter industry");
            return;
        }

        const updatedData = { industry: value };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/onboard/product')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (value?.length < 1) {
            alert("Enter industry");
            return;
        }

        const updatedData = { industry: value };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/onboard/role')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                <h1 className='question-onboard__title question-onboard__title--required'>Which industry do you work in?</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question-onboard__field'>
                    <Space
                        style={{
                            width: '100%',
                        }}
                        direction="vertical"
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            // style={{
                            //     width: '100%',
                            // }}
                            value={value}
                            className='question-onboard__multi-select'
                            placeholder="Please select"
                            // defaultValue={['a10', 'c12']}
                            onChange={(_value) => setValue(_value)}
                            options={options}
                        />
                    </Space>
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default Industry