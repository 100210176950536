import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, saveData, updateData } from '../../utils/saveData';
import { url } from '../../constants/regex';
import dayjs, { Dayjs } from 'dayjs';

function CompanyName() {
    const navigate = useNavigate();
    const regex = /^[a-zA-Z0-9\s\-.,]{2,50}$/;

    let collectionName;

    const docId = "brand_details";

    const [value, setValue] = useState("");
    const [website, setWebsite] = useState("");
    const [inception, setInception] = useState("");

    useEffect(() => {
        if (localStorage.getItem("uname")) {
            collectionName = localStorage.getItem("uname");

            const fetchData = async () => {
                const retrievedData = await getData(collectionName, docId);
                if (retrievedData?.company_name) {
                    setValue(retrievedData?.company_name)
                }
                if (retrievedData?.website) {
                    setWebsite(retrievedData?.website)
                }
                if (retrievedData?.inception_date) {
                    setInception(dayjs(String(retrievedData?.inception_date)))
                }
            };
            fetchData();
        }
        else {
            navigate('/');
        }


    }, []);

    function validateYear() {
        if (!+inception?.$y) return true;
        // Ensure value is a number and exactly 4 digits
        const year = +inception?.$y; // or simply use value if not using a specific format
        const currentYear = new Date().getFullYear();

        if (isNaN(year) || String(inception?.$y).length !== 4) {
            alert("Year should be a 4-digit number.");
            return false;
        }

        if (year < 1900) {
            alert("Year should be more than 1900.");
            return false;
        }

        if (year >= currentYear) {
            alert(`Year should be less than ${currentYear}.`);
            return false;
        }

        return true; // Year is valid
    }


    const onNext = async () => {

        if (!value) {
            alert("Enter company's name.");
            return;
        }

        if (!regex.test(value)) {
            alert("Invalid name!");
            return;
        }

        if (!website) {
            alert("Enter Website");
            return;
        }

        const regexweb = new RegExp(url);
        if (!website.match(regexweb)) {
            window.alert("URL seems incorrect!");
            return;
        }

        if (!validateYear()) return;

        const updatedData = { company_name: value, website: website, inception_date: inception?.$y || "" };
        try {
            const res = await checkDocumentExists(localStorage.getItem("uname"), docId)
            if (res) {
                // update if the user clicks previous from any screen
                await updateData(localStorage.getItem("uname"), docId, updatedData);
                navigate('/onboard/fullname')

            }
            else {
                // as this is first page, the document must be created if it is the first save
                await saveData(localStorage.getItem("uname"), docId, updatedData);
                navigate('/onboard/fullname')

            }
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter company's name.");
            return;
        }

        if (!regex.test(value)) {
            alert("Invalid name!");
            return;
        }

        if (!website) {
            alert("Enter Website");
            return;
        }

        const regexweb = new RegExp(url);
        if (!website.match(regexweb)) {
            window.alert("URL seems incorrect!");
            return;
        }

        if (!validateYear()) return;

        const updatedData = { company_name: value, website: website, inception_date: inception?.$y || "" };
        

        const res = await checkDocumentExists(localStorage.getItem("uname"), docId)
        if (res) {
            // update if the user clicks previous from any screen
            await updateData(localStorage.getItem("uname"), docId, updatedData);
            window.alert("Saved!");
        }
        else {
            console.log("bye")
            // as this is first page, the document must be created if it is the first save
            await saveData(localStorage.getItem("uname"), docId, updatedData);
            window.alert("Saved!");
        }
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                {/* <h1 className='question-onboard__title question-onboard__title--required'>Enter your company's name</h1> */}
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question-onboard__field'>
                    <Input placeholder="*Enter your company's name" value={value} onChange={(e) => { setValue(e.target.value) }} />
                </div>
                <div className='question-onboard__field'>
                    <Input placeholder="*Enter your company's website" value={website} onChange={(e) => setWebsite(e.target.value)} />
                </div>
                <div className='question-onboard__field'>
                    <DatePicker onChange={(e) => { setInception(e) }} value={inception} placeholder="Enter your company's inception year"
                        picker="year" style={{ width: "100%" }} />
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        {/* <Button size='large' className='question-onboard__prv-btn'>&larr; Previous</Button> */}
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default CompanyName