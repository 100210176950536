import React from 'react';
import { Spin } from 'antd'; // Importing the Spin component
// import 'antd/dist/antd.css'; // Importing the antd styles

const Loader = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {/* Loader */}
      <Spin size="large" />

      {/* Text Below Loader */}
      <p style={{ marginTop: '10px', fontSize: "16px", color: 'white' }}>
        <div>
          <p>Hang tight! Passion AI is working its magic to find the perfect influencers for your campaign. </p>
        </div>
      </p>
      <p style={{ marginTop: '10px', fontSize: "16px", color: 'white' }}>
        Your personalized list will be ready shortly!
      </p>
    </div>
  );
};

export default Loader;
