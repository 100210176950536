import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

function VoiceAndTone() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [value, setValue] = useState("");

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.tone) {
                        setValue(retrievedData?.tone)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }
        }
        load();
    }, []);

    const onNext = async () => {

        const updatedData = {
            tone: value,
            passion_credits: 500000
        };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {

        const updatedData = { tone: value };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/onboard/socialmedia')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                <h1 className='question-onboard__title'>Which personality/communication styles does your brand relate to?</h1>
                <h4>Brand voice refers to the unique personality and language used by a brand to communicate with its audience, conveying its values, attitude, and emotions through every interaction.</h4>
                <div className='question-onboard__field'>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "friendly"} type='radio' onClick={() => { setValue("friendly") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Friendly and Approachable:
                            </p>
                            <p className='question-onboard__checkbox-subtitle'>
                                This tone is warm, inviting, and conversational. It's like having a chat with a friend. gg. Coca-Cola and Dove
                            </p>
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "pro"} type='radio' onClick={() => { setValue("pro") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Professional and Authoritative:
                            </p>
                            <p className='question-onboard__checkbox-subtitle'>
                                This tone is formal, expert, and trustworthy. It's like consulting a specialist. eg. IBM and Harvard Business Review
                            </p>
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "fun"} type='radio' onClick={() => { setValue("fun") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Fun and Playful:
                            </p>
                            <p className='question-onboard__checkbox-subtitle'>
                                This tone is lighthearted, humorous, and entertaining. It's like having a good time with friends. eg. Lego and Oreo
                            </p>
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "edgy"} type='radio' onClick={() => { setValue("edgy") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Edgy and Bold:
                            </p>
                            <p className='question-onboard__checkbox-subtitle'>
                                This tone is confident, provocative, and disruptive. It's like being part of a revolution. eg. Nike and Red Bull
                            </p>
                        </div>
                    </div>
                    <div className='question-onboard__checkbox-container'>
                        <input checked={value === "empathy"} type='radio' onClick={() => { setValue("empathy") }} />
                        <div>
                            <p className='question-onboard__checkbox-title'>
                                Empathetic and Supportive:
                            </p>
                            <p className='question-onboard__checkbox-subtitle'>
                                This tone is compassionate, understanding, and encouraging. It's like having a supportive friend. eg. Dabur and Calm
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default VoiceAndTone