import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig.js";

// Function to save JSON data to Firestore
export const saveData = async (collectionName, docId, jsonData) => {
    try {
        await setDoc(doc(db, collectionName, docId), jsonData);
        console.log("Document written with ID: ", docId);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};

// Function to retrieve JSON data from Firestore
export const getData = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        console.log("No such document!");
        return null;
    }
};

// Function to update a document in Firestore
export const updateData = async (collectionName, docId, updateData) => {
    try {
        console.log(db, collectionName, docId)
        const docRef = doc(db, collectionName, docId);
        await updateDoc(docRef, updateData);
        console.log("Document successfully updated!");
    } catch (e) {
        console.error("Error updating document: ", e);
    }
};

export const checkDocumentExists = async (collectionName, docId) => {
    console.log(collectionName, docId)
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document exists:", docSnap.data());
        return true;
    } else {
        console.log("No such document!");
        return false;
    }
};
