import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';


function Objective() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [value, setValue] = useState([]);

    useEffect(() => {
        const load = async () => {
            // for that id we will check if objective is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.objective) {
                            setValue(currentCampaign?.objective)
                        }
                    }
                    else {
                        // go back
                    }
                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load()
    }, []);

    const onNext = async () => {
        if (!value?.length) {
            alert("Enter the objective!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.objective = value;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/budget')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value?.length) {
            alert("Enter the objective!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.objective = value;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    // const onPrevious = () => {
    //     // navigate('/onboard/socialmedia')
    // }

    const onSelect = (e, _value) => {
        const list = [...value]
        if (e.target.checked) {
            list.push(_value);
            setValue(list)
        }
        else {
            list.splice(value.indexOf(_value), 1)
            setValue(list)
        }
    }

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title'>What do you want to achieve from this campaign?</h1>
                {/* <h4>Brand voice refers to the unique personality and language used by a brand to communicate with its audience, conveying its values, attitude, and emotions through every interaction.</h4> */}
                <div className='question__field'>
                    <div className='question__checkbox-container'>
                        <input
                            checked={value.includes("awareness")}
                            type='checkbox'
                            onClick={(e) => { onSelect(e, "awareness") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Awareness
                            </p>
                            <p className='question__checkbox-subtitle'>
                                Best for Reach/ Branding
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={value.includes("traffic")}
                            type='checkbox'
                            onClick={(e) => { onSelect(e, "traffic") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Traffic
                            </p>
                            <p className='question__checkbox-subtitle'>
                                Best for Visits/ Link clicks/ Calls
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={value.includes("engagement")}
                            type='checkbox'
                            onClick={(e) => { onSelect(e, "engagement") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Engagement
                            </p>
                            <p className='question__checkbox-subtitle'>
                                Best for Likes/ Comments/ Saves/ Shares
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={value.includes("leads")}
                            type='checkbox'
                            onClick={(e) => { onSelect(e, "leads") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Leads
                            </p>
                            <p className='question__checkbox-subtitle'>
                                Best for Conversions/ Form fill
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={value.includes("purchase")}
                            type='checkbox'
                            onClick={(e) => { onSelect(e, "purchase") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Purchase
                            </p>
                            <p className='question__checkbox-subtitle'>
                                Best for Sales/ Catalog Sale/ App Install
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input
                            checked={value.includes("ugc")}
                            type='checkbox'
                            onClick={(e) => { onSelect(e, "ugc") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Content Generation
                            </p>
                            <p className='question__checkbox-subtitle'>
                                Best for UGC/ Performance Ads
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        {/* <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button> */}
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default Objective