import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

function Platform() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [value, setValue] = useState(null);

    useEffect(() => {
        const load = async () => {
            // for that id we will check if platform is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.platform) {
                            setValue(currentCampaign?.platform)
                        }
                    }
                    else {
                        // go back
                    }
                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load()
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter the platform!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.platform = value;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/audience')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter the platform!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.platform = value;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/campaign/timeline')
    }

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title'>Select the platform you wish to run your campaign on</h1>
                <div className='question__field'>
                    <div className='question__checkbox-container'>
                        <input checked={value === "instagram"} type='radio' onClick={() => { setValue("instagram") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Instagram
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input checked={value === "fb"} type='radio' onClick={() => { setValue("fb") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                Facebook
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input checked={value === "youtube"} type='radio' onClick={() => { setValue("youtube") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                YouTube
                            </p>
                        </div>
                    </div>
                    <div className='question__checkbox-container'>
                        <input checked={value === "linkedin"} type='radio' onClick={() => { setValue("linkedin") }} />
                        <div>
                            <p className='question__checkbox-title'>
                                LinkedIn
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default Platform