import { Route, Routes } from 'react-router-dom'
import React from 'react'
import Login from './modules/login/Login'
import BrandOnboarding from './modules/onboarding/BrandOnboarding';
import BrandCampaign from './modules/campaign/BrandCampaign';
import Home from './modules/dashboard/Home';
import Objective from './components/Campaign/Objective';
import CampaignTable from './modules/dashboard/campaignTable';
import CampaignTableNew from './modules/dashboard/campaignTablenew';
import Signup from './modules/login/Signup';

function PassionRoutes() {
    return (
        <Routes>
            <Route exact key='login' path='/' Component={Login} />
            <Route key='signup' path='/signup' Component={Signup} />
            <Route key='onboard' path='/onboard/:id' Component={BrandOnboarding} />
            <Route key='campaign' path='/campaign/:id' Component={BrandCampaign} />
            <Route key='home' path='home' Component={Home} />
            {/* <Route key='campaign-detail' path='campaign-detail' Component={CampaignTable} /> */}
            <Route key='campaign-detail' path='campaign-detail-new' Component={CampaignTableNew} />
            {/* <Route key='objective' path='objective' Component={Objective} /> */}
        </Routes>
    )
}

export default PassionRoutes;