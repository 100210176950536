import React, { useState } from 'react';
import { Modal, Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';

const AnalyticsModal = ({ open, handleClose, handleOpen, data, campaignSelected }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

    const influencerAnalytics = [
        { title: 'User Name', value: campaignSelected?.payment ? data?.username : "****" },
        { title: 'Passion Score', value: data?.passion_score },
        { title: 'Followers', value: data?.followers },
        { title: 'Engagement Rate', value: `${(data?.engagement_rate * 100).toFixed(3)}%` },
        // { title: 'Likes per Post', value: '10K' },
        // { title: 'Comments per Post', value: '500' },
        // { title: 'Shares per Post', value: '200' },
        // { title: 'Views per Video', value: '150K' },
        // { title: 'Brand Collaborations', value: '20' },
        { title: 'Reach', value: data?.avg_reach },
        { title: 'Reel Play in 30d', value: data?.avg_reels_play_30d },
        { title: 'Average Likes', value: data?.average_likes },
        // { title: 'Audience Growth', value: data?.profile_pic }
    ];

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Influencer Analytics
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={data?.profile_pic} width="80" style={{ borderRadius: "50%" }} />
                        </Grid>
                        {influencerAnalytics.map((analytic, index) => (
                            <>
                                <Grid item xs={6} key={index}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">{analytic.title}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {analytic.value}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
};

export default AnalyticsModal;