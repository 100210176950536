import React from 'react';
import { useParams } from 'react-router-dom';
import { BrandOnboardingSteps } from "../../constants/onboardingSteps";
import WhatDoYouSell from '../../components/BrandOnboarding/WhatDoYouSell';
import { Flex } from 'antd';
import PassionPanel from '../../components/PassionPanel';
import BrandWebsite from '../../components/BrandOnboarding/BrandWebsite';
import Industry from '../../components/BrandOnboarding/Industry';
import Tagline from '../../components/BrandOnboarding/Tagline';
import SocialMedia from '../../components/BrandOnboarding/SocialMedia';
import VoiceAndTone from '../../components/BrandOnboarding/VoiceAndTone';
import InceptionDate from '../../components/BrandOnboarding/InceptionDate';
import Keywords from '../../components/BrandOnboarding/Keywords';
import PhoneNumber from '../../components/BrandOnboarding/PhoneNumber';
import RoleInCompany from '../../components/BrandOnboarding/RoleInCompany';
import FullName from '../../components/BrandOnboarding/FullName';
import CompanyName from '../../components/BrandOnboarding/CompanyName';


function BrandOnboarding() {
    // use path params to judge the which page to open
    // Params will help you start a journey from any point, BE will return the data till that point and previous pages will be prefilled
    // save, previous, next buttons and finally submit
    // user can save till a point and save API will be called.
    // JSON to store title and subtitle wrt to component and page path slug?

    const { id } = useParams();

    return (
        <Flex vertical={false} className='onboard__container'>
            {/* <div className='onboard__panel'>
                <PassionPanel />
            </div> */}
            <div className='onboard__box'>
                <div className='onboard__left'>
                    <img src='https://thepassionai.in/logo_passion_new.jpeg' alt='PassionAI' height={120} width={120} />
                    <h2>
                        Create your Passion Account
                    </h2>
                </div>
                <div className='onboard__right'>
                    {(() => {
                        switch (id) {
                            case 'product':
                                return <WhatDoYouSell />;
                            // case 'website':
                            //     return <BrandWebsite />;
                            case 'industry':
                                return <Industry />;
                            // skip for now
                            // case 'tagline':
                            //     return <Tagline />;
                            case 'keywords':
                                return <Keywords />;
                            case 'socialmedia':
                                return <SocialMedia />;
                            case 'tone':
                                return <VoiceAndTone />;
                            // case 'started':
                            //     return <InceptionDate />;
                            // case 'phonenumber':
                            //     return <PhoneNumber />;
                            case 'role':
                                return <RoleInCompany />;
                            case 'companyname':
                                return <CompanyName />;
                            case 'fullname':
                                return <FullName />;
                            default:
                                return <div>Error 404</div>;
                        }
                    })()}
                </div>

            </div>
        </Flex>
    )
}

export default BrandOnboarding