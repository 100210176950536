import React, { useState } from 'react'
import { Button, Flex, Image, Input } from "antd";
import PassionPanel from '../../components/PassionPanel';
import { checkDocumentExists, getData } from '../../utils/saveData';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const docId = "login_details";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const onSubmit = () => {
        if (!email || !pass) {
            alert("Please enter email and password");
            return;
        }
        if (!emailRegex.test(email)) {
            alert("Invalid email.")
            return;
        }
        if (checkDocumentExists(email, docId)) {
            const fetchData = async () => {
                const retrievedData = await getData(email, docId);
                console.log(retrievedData)
                if (retrievedData?.pass !== pass) {
                    alert("Invalid email or password.");
                }
                else {
                    localStorage.setItem("uname", email)
                    navigate('/home')
                }
            };
            fetchData();
        }
    }

    return (
        <Flex vertical={false} className='login__container'>
            <div className='login__form'>
                <div className='login__left'>
                    <img src='https://thepassionai.in/logo_passion_new.jpeg' alt='PassionAI' height={120} width={120} />
                    <h2>
                        Login to your Passion Account
                    </h2>
                </div>
                <form className='login__right'>
                    {/* <img src='https://thepassionai.in/logo_passion_new.jpeg' alt='PassionAI' height={150} width={150} /> */}
                    <div className='login__inputs'>
                        {/* <label for="username">Email</label> */}
                        <Input type='text' placeholder='Email' id='username' value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='login__inputs'>
                        {/* <label for="password">Password</label> */}
                        <Input type={showPassword ? 'text' : 'password'} placeholder='Password' id='password' value={pass}
                            onChange={(e) => setPass(e.target.value)} />
                        {
                            !showPassword ? <svg className='login__eye' xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#245fd6" viewBox="0 0 256 256"
                                onClick={() => { setShowPassword(!showPassword); }}>
                                <path d="M251,123.13c-.37-.81-9.13-20.26-28.48-39.61C196.63,57.67,164,44,128,44S59.37,57.67,33.51,83.52C14.16,102.87,5.4,122.32,5,123.13a12.08,12.08,0,0,0,0,9.75c.37.82,9.13,20.26,28.49,39.61C59.37,198.34,92,212,128,212s68.63-13.66,94.48-39.51c19.36-19.35,28.12-38.79,28.49-39.61A12.08,12.08,0,0,0,251,123.13Zm-46.06,33C183.47,177.27,157.59,188,128,188s-55.47-10.73-76.91-31.88A130.36,130.36,0,0,1,29.52,128,130.45,130.45,0,0,1,51.09,99.89C72.54,78.73,98.41,68,128,68s55.46,10.73,76.91,31.89A130.36,130.36,0,0,1,226.48,128,130.45,130.45,0,0,1,204.91,156.12ZM128,84a44,44,0,1,0,44,44A44.05,44.05,0,0,0,128,84Zm0,64a20,20,0,1,1,20-20A20,20,0,0,1,128,148Z">
                                </path>
                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" className='login__eye' width="32" height="32" fill="#245fd6" viewBox="0 0 256 256" onClick={() => { setShowPassword(!showPassword); }}>
                                <path d="M56.88,31.93A12,12,0,1,0,39.12,48.07l16,17.65C20.67,88.66,5.72,121.58,5,123.13a12.08,12.08,0,0,0,0,9.75c.37.82,9.13,20.26,28.49,39.61C59.37,198.34,92,212,128,212a131.34,131.34,0,0,0,51-10l20.09,22.1a12,12,0,0,0,17.76-16.14ZM128,188c-29.59,0-55.47-10.73-76.91-31.88A130.69,130.69,0,0,1,29.52,128c5.27-9.31,18.79-29.9,42-44.29l90.09,99.11A109.33,109.33,0,0,1,128,188Zm123-55.12c-.36.81-9,20-28,39.16a12,12,0,1,1-17-16.9A130.48,130.48,0,0,0,226.48,128a130.36,130.36,0,0,0-21.57-28.12C183.46,78.73,157.59,68,128,68c-3.35,0-6.7.14-10,.42a12,12,0,1,1-2-23.91c3.93-.34,8-.51,12-.51,36,0,68.63,13.67,94.49,39.52,19.35,19.35,28.11,38.8,28.48,39.61A12.08,12.08,0,0,1,251,132.88Z"></path></svg>
                        }
                        {/* <a style={{ textAlign: "right" }}>Forgot Password?</a> */}
                    </div>
                    <div className='login__actions'>
                        <div className='login__signup'>
                            <p>Don't have an account?</p>
                            <a href='/signup'>SIGN UP</a>
                        </div>
                        <div>
                            <Button type='primary' size='small' className='login__btn' onClick={onSubmit}>LOG IN</Button>
                        </div>
                    </div>

                </form>
            </div>
        </Flex>
    )
}

export default Login