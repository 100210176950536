import React, { useEffect, useState } from 'react';
import { Button, Flex, Input } from "antd";
import { doc, setDoc } from "firebase/firestore";
import PassionPanel from '../../components/PassionPanel';
import { checkDocumentExists, getData } from '../../utils/saveData';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';  // import both auth and db
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';

function Signup() {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [repass, setRePass] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();
    const docId = "login_details";

    // Password and email regex
    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const onSubmit = async () => {
        if (!email || !pass || !repass) {
            alert("Please enter email and password");
            return;
        }

        // if (!isBusinessEmail(email)) {
        //     alert("Please use a business email.");
        //     return;
        // }

        if (!passRegex.test(pass)) {
            alert("Your password must be at least 8 characters long and include:\n" +
                "- At least one uppercase letter\n- At least one lowercase letter\n" +
                "- At least one number\n- At least one special character (e.g., !@#$%^&*)");
            return;
        }

        if (pass !== repass) {
            alert("Your passwords do not match");
            return;
        }

        if (!emailRegex.test(email)) {
            alert("Invalid email.");
            return;
        }

        await handleEmailNotif();
    };

    function isBusinessEmail(_email) {
        // List of common free email domains
        const freeDomains = [
            'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com',
            'icloud.com', 'aol.com', 'zoho.com', 'protonmail.com', 'yandex.com'
        ];

        // Extract the domain from the email
        const emailDomain = _email.split('@')[1].toLowerCase();

        // Check if the domain is in the freeDomains list
        return !freeDomains.includes(emailDomain);
    }

    const pingEmailVerification = () => {
        const intervalId = setInterval(async () => {
            const user = auth.currentUser;
            if (user) {
                // Reload the user's data to get the latest status
                await user.reload();

                if (user.emailVerified) {
                    console.log('Email verified');
                    setIsVerified(true);
                    await postEmailVerification();
                    clearInterval(intervalId); // Stop checking once email is verified
                } else {
                    console.log('Email not verified');
                }
            }
        }, 5000);
    };


    const handleEmailNotif = async () => {
        setIsLoading(true);
        try {
            // Create the user with email and password
            const userCredential = await createUserWithEmailAndPassword(auth, email, pass);
            const user = userCredential.user;

            // Send email verification
            await sendEmailVerification(user);
            alert("Verification email sent. Please check your inbox.");
            pingEmailVerification()

        } catch (error) {
            alert(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const postEmailVerification = async () => {
        // if (await checkDocumentExists(email, docId)) {
        console.log("what")
        const retrievedData = await getData(email, docId);
        if (!retrievedData) {
            console.log("here", retrievedData)
            // If it's a new account, create a new document
            try {
                const docRef = doc(db, email, docId);
                await setDoc(docRef, { pass });
                localStorage.setItem("uname", email);
                navigate("/onboard/companyname");
            } catch (e) {
                console.error('Error adding document:', e);
            }
        } else {
            console.log("no")
            alert("There is already an account with this email.");
        }
        // }
    };

    return (
        <Flex vertical={false} className='login__container'>
            <div className='login__form'>
                <div className='login__left'>
                    <img src='https://thepassionai.in/logo_passion_new.jpeg' alt='PassionAI' height={150} width={150} />
                    <h2>Create your Passion Account</h2>
                </div>
                <form className='login__right'>
                    <div className='login__inputs'>
                        <Input
                            type='text'
                            placeholder='Enter Email'
                            id='username'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='login__inputs'>
                        <Input
                            type='password'
                            placeholder='Enter Password'
                            id='password'
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                        />
                        <div className='login__hints'>
                            <p>Must be at least 8 characters and have at least 1 uppercase letter, lowercase letter, number, and special character.</p>
                        </div>
                    </div>
                    <div className='login__inputs'>
                        <Input
                            type='password'
                            placeholder='Re-Enter Password'
                            id='password'
                            value={repass}
                            onChange={(e) => setRePass(e.target.value)}
                        />
                    </div>
                    <Button
                        type='primary'
                        size='large'
                        className='login__btn'
                        onClick={onSubmit}
                        disabled={isLoading}
                    >
                        SIGN UP
                    </Button>
                </form>
            </div>
        </Flex>
    );
}

export default Signup;