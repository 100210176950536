import { BrowserRouter as Router } from "react-router-dom";
import PassionRoutes from "./Routes";
import "./index.scss";
import './tailwind.css';

function App() {
  return (
    <Router>
      <PassionRoutes />
    </Router>
  );
}

export default App;
