import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom"
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

const { TextArea } = Input;

function WhatDoYouSell() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [value, setValue] = useState("");

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.product) {
                        setValue(retrievedData?.product)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }

        }
        load();
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter product");
            return;
        }

        if (value?.trim().length < 30) {
            alert("This should not be less than 30 characters");
            return;
        }

        if (value?.trim().length > 300) {
            alert("This should not be more than 300 characters");
            return;
        }

        const updatedData = { product: value };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/onboard/keywords')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter product");
            return;
        }

        if (value?.trim().length < 30) {
            alert("This should not be less than 30 characters");
            return;
        }

        if (value?.trim().length > 300) {
            alert("This should not be more than 300 characters");
            return;
        }

        const updatedData = { product: value };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/onboard/industry')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                <h1 className='question-onboard__title question-onboard__title--required'>What do you sell?</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question-onboard__field'>
                    <TextArea rows={5}
                        placeholder="We sell strategic advice and expertise to help organizations improve performance and achieve lasting impact."
                        value={value}
                        onChange={(e) => { setValue(e?.target?.value) }}
                    />
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default WhatDoYouSell