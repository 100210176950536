import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { Button, Input } from "antd";
const { TextArea } = Input;

function CommentModal({ open, handleClose, value, setValue, onSubmit }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <div className='question__field'>
                        <TextArea rows={10}
                            value={value}
                            style={{ color: 'black' }}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter your reason for rejection"
                        />
                        <Button onClick={onSubmit}>Submit</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default CommentModal