import React, { useEffect, useState } from 'react';
import { MagnifyingGlass, Bell, TShirt, SneakerMove, Sneaker, ArrowRight, Orange, SignOut, MetaLogo, TrendUp, User } from "@phosphor-icons/react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getData, saveData, updateData } from '../../utils/saveData';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig';
// import { getFirestore, arrayRemove, arrayUnion } from "firebase/firestore";
import AnalyticsModal from '../../components/AnalyticsModal';
import CountModal from '../../components/CountModal';
import { Button } from 'antd';
import Loader from '../../components/Loader';
import CommentModal from '../../components/CommentModal';
import PaymentModal from '../../components/PaymentModal';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CampaignTableNew() {
    const navigate = useNavigate();
    const query = useQuery();
    // const MULTIPLIER = 1.5;

    const campaignId = query.get('id');
    // console.log(campaignId)
    let collectionName;


    const docId = "campaign_details";

    const [campaigns, setCampaigns] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [totalReach, setTotalReach] = useState(0)
    const [totalEngRate, setTotalEngRate] = useState(0)
    const [tabSelected, setTabSelected] = useState("selected")
    const [campaignSelected, setCampaignSelected] = useState("")
    const [companyDetails, setCompanyDetails] = useState({})

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [viewCount, setViewCount] = useState(5);
    const [customCount, setCustomCount] = useState(null);
    const [selectedUserForQuickView, setSelectedUserForQuickView] = useState(null);
    const [openCountModal, setOpenCountModal] = useState(false);
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [openPaymenttModal, setOpenPaymentModal] = useState(false);
    const [rejectionComment, setRejectionComment] = useState('');

    const handleClickOpenCountModal = () => {
        setOpenCountModal(true);
    };

    const handleCloseCountModal = () => {
        setOpenCountModal(false);
    };

    const handleClickOpenPaymentModal = () => {
        setOpenPaymentModal(true);
    };

    const handleClosePaymentModal = () => {
        setOpenPaymentModal(false);
    };

    const handleClickOpenCommentModal = () => {
        setOpenCommentModal(true);
    };

    const handleCloseCommentModal = () => {
        setOpenCommentModal(false);
    };

    const handleOpen = (item) => {
        setOpen(true);
        setSelectedUserForQuickView(item)
    }
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (localStorage.getItem("uname")) {
            collectionName = localStorage.getItem("uname");
            // debugger
            getDataForCampaigns(query.get('id'))
        }
        else {
            navigate('/');
        }
    }, [])

    useEffect(() => {
        // if len no then loader and then run interval
        // clear interval if sel cam change
        //  stop internal if len > 0

        let interval = null;
        if (!campaignSelected?.selected || campaignSelected.selected.length === 0) {
            interval = setInterval(() => {
                getDataForCampaigns(campaignSelected?.campaign_id);
            }, 30000);
        }

        return () => {
            clearInterval(interval)
        }
    }, [campaignSelected?.campaign_id])

    const getDataForCampaigns = async (_campaignId) => {
        console.log("getDataForCampaigns")
        const retrievedData = await getData(localStorage.getItem("uname"), docId);
        setCampaigns(retrievedData?.list?.length ? [...retrievedData?.list] : [])
        if (retrievedData?.list?.length) {
            const list = retrievedData?.list.find((item) => { return item?.campaign_id == _campaignId });
            setCampaignSelected(list)
            const visibilityList = []
            let totalReach = 0;
            let totalEngRate = 0;
            let count = 0;
            if (list?.selected?.length) {
                setIsLoading(false);
                list?.selected?.forEach((item) => {
                    if (item?.visible) {
                        totalReach += item?.avg_reach
                        totalEngRate += item?.engagement_rate;
                        count += 1;
                        visibilityList.push(item?.username)
                    }
                })
                setTotalReach(totalReach);
                setTotalEngRate(totalEngRate / count);
                setSelectedList(visibilityList)
            }
            else {
                setIsLoading(true);
            }

        }
        const brandData = await getData(localStorage.getItem("uname"), "brand_details");
        setCompanyDetails(brandData)
    }


    const sendRequest = async () => {
        const userRef = doc(db, localStorage.getItem("uname"), docId);
        // debugger
        try {
            // Step 1: Get the document snapshot
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const data = userDoc.data();
                let list = data.list || [];

                const oldTaskIndex = list.findIndex(task => task?.campaign_id === campaignSelected?.campaign_id);

                if (oldTaskIndex !== -1) {
                    const oldTask = list[oldTaskIndex];
                    const selectedArray = oldTask.selected || [];
                    selectedArray?.forEach((item) => {
                        if (item?.visible) {
                            item.brand_accepted = true;
                            item.user_accepted = false;
                        }
                        else {
                            item.brand_accepted = false;
                            item.user_accepted = false;
                        }
                    })

                    list[oldTaskIndex].selected = selectedArray;
                    list[oldTaskIndex].brand_accepted_timestamp = (new Date()).toISOString()

                    await updateDoc(userRef, {
                        list: list
                    });

                    window.alert("Request sent!");

                } else {
                    console.error("Task not found in the list array.");
                }
            } else {
                console.error("Document does not exist!");
            }
        } catch (error) {
            console.error("Error updating task status: ", error);
        }
    }

    const createNewCampaign = async () => {
        // It is assumed that no campaigns will be deleted once created it will remain part of the list, It can be in edit mode or submitted mode only.
        const list = [...campaigns];
        const id = list.length + 1
        list.push({
            campaign_id: id,
            status: "edit"
        })
        await saveData(collectionName, docId, { list });
        localStorage.setItem("current_campaign_id", id);
        navigate('/campaign/objective')

    }

    // id
    // CF10317119CRN9RAH63CDS73EKIV90
    // secret
    // cfsk_ma_test_8250df8f53a4341aa0d61d7a483a2842_d4895723

    async function updateNestedKey() {
        // Reference to the subcollection document
        const subDocRef = doc(db, collectionName, docId, 'list', 'subDocumentId');
        debugger;
        try {
            // Update the specific key in the document
            await updateDoc(subDocRef, {
                keyToUpdate: "new value" // Update keyToUpdate with a new value
            });
            console.log("Document successfully updated!");
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }


    // Update the document in the collection
    async function updateTaskStatus(item, status) {
        const userRef = doc(db, localStorage.getItem("uname"), docId);
        // debugger
        try {
            // Step 1: Get the document snapshot
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const data = userDoc.data();
                let list = data.list || [];
                // debugger;
                // Step 2: Find the campaign and update the visibility
                const oldTaskIndex = list.findIndex(task => task?.campaign_id === campaignSelected?.campaign_id); // Find the index of the old task
                if (oldTaskIndex !== -1) {
                    const oldTask = list[oldTaskIndex];
                    const selectedArray = oldTask.selected || [];
                    const itemIndex = selectedArray.findIndex(data => data?.username === item?.username);

                    if (itemIndex !== -1) {
                        // Step 3: Update the visibility of the selected item
                        selectedArray[itemIndex].visible = status; // this flag means if the checkbox is checked or not for BE
                        // debugger;
                        // Step 4: Update the campaign in the list array
                        list[oldTaskIndex].selected = selectedArray;

                        // Step 5: Write the updated list back to Firestore
                        await updateDoc(userRef, {
                            list: list
                        });

                        console.log("Task status updated successfully!");
                    } else {
                        console.error("Item not found in the selected array.");
                    }
                } else {
                    console.error("Task not found in the list array.");
                }
            } else {
                console.error("Document does not exist!");
            }
        } catch (error) {
            console.error("Error updating task status: ", error);
        }
    }

    const onCustomClick = async () => {
        // add loader
        setIsLoading(true);
        handleCloseCountModal()

        const retrievedData = await getData(localStorage.getItem("uname"), docId);
        const finalList = [...retrievedData?.list]
        finalList?.forEach((item) => {
            if (item.campaign_id == campaignSelected.campaign_id) {
                item.custom_infl_count = customCount;
            }
        })

        try {
            await updateData(localStorage.getItem("uname"), docId, { list: finalList });
            // window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onDefaultClick = async () => {
        // add loader and then run script yourself
        setIsLoading(true);
        handleCloseCountModal()
        // remove count from fb
        const retrievedData = await getData(localStorage.getItem("uname"), docId);
        const finalList = [...retrievedData?.list]
        finalList?.forEach((item) => {
            if (item.campaign_id == campaignSelected.campaign_id) {
                item.custom_infl_count = 0;
            }
        })

        try {
            await updateData(localStorage.getItem("uname"), docId, { list: finalList });
            // window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const listHandlerLocal = (flag, _username) => {
        const removeNumber = (array, uname) => {
            return array.filter(item => item !== uname);
        };

        const list = [...selectedList];

        if (flag) {
            list.push(_username);
        }
        else {
            const updatedList = removeNumber(list, _username);
            list.length = 0; // Clear the list
            list.push(...updatedList);
        }

        setSelectedList(list);
    }

    const listHandler = (flag, item) => {
        updateTaskStatus(item, flag) // to update FB
        listHandlerLocal(flag, item?.username) // to update local state so that checkbox can change dynamically in FE
    }

    const updateDraftStatus = async (item, status, _comment = '') => {
        const userRef = doc(db, localStorage.getItem("uname"), docId);

        try {

            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const data = userDoc.data();
                let list = data.list || [];

                const oldTaskIndex = list.findIndex(task => task?.campaign_id === campaignSelected?.campaign_id);

                if (oldTaskIndex !== -1) {
                    const oldTask = list[oldTaskIndex];
                    const selectedArray = oldTask.selected || [];
                    const itemIndex = selectedArray.findIndex(data => data?.username === item?.username);

                    if (itemIndex !== -1) {
                        if (status) {
                            selectedArray[itemIndex].draft_accepted = true;
                        }
                        else {
                            selectedArray[itemIndex].draft_accepted = false;
                            const list = selectedArray[itemIndex]?.draft_comment ?? [];
                            list.push({
                                comment: _comment,
                                version: list[(list.length) - 1]?.version + 1 || 1
                            })

                            selectedArray[itemIndex].draft_comment = list;
                            localStorage.removeItem('rejection_item')
                        }

                        list[oldTaskIndex].selected = selectedArray;

                        await updateDoc(userRef, {
                            list: list
                        });

                        getDataForCampaigns(campaignSelected?.campaign_id);

                        window.alert("Task status updated successfully!");
                    } else {
                        console.error("Item not found in the selected array.");
                    }
                } else {
                    console.error("Task not found in the list array.");
                }
            } else {
                console.error("Document does not exist!");
            }
        } catch (error) {
            console.error("Error updating task status: ", error);
        }
    }

    const onCommentSubmit = () => {
        handleCloseCommentModal()
        updateDraftStatus(JSON.parse(localStorage.getItem('rejection_item')), false, rejectionComment)
        window.alert('Review submitted to influencer!')
    }

    function formatToIndianLakhSystem(number) {
        if (!number) return '0';

        const num = number.toString().split('.');
        let lastThreeDigits = num[0].slice(-3);
        let otherDigits = num[0].slice(0, -3);

        if (otherDigits !== '') {
            lastThreeDigits = ',' + lastThreeDigits;
        }

        const formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;

        return num.length > 1 ? formattedNumber + '.' + num[1] : formattedNumber;
    }

    function openVideo(event, driveUrl) {
        event.preventDefault();  // Prevent the default link behavior

        // Extract the file ID from the Google Drive URL
        const fileIdMatch = driveUrl.match(/\/d\/(.+?)\//);
        const fileId = fileIdMatch ? fileIdMatch[1] : null;

        if (fileId) {
            // Construct the direct video link for Google Drive
            const videoLink = `https://drive.google.com/uc?export=preview&id=${fileId}`;

            // Open the video in a new window or tab
            const videoWindow = window.open("", "_blank", "width=800,height=600");
            videoWindow.document.write(`
                <html>
                    <head><title>Video Player</title></head>
                    <body style="margin:0;padding:0;">
                        <iframe src="${videoLink}" frameborder="0" style="width:100%;height:100%;" allowfullscreen></iframe>
                    </body>
                </html>
            `);
            videoWindow.document.close();
        } else {
            alert("Invalid Google Drive URL");
        }
    }

    const onPay = async () => {
        const retrievedData = await getData(localStorage.getItem("uname"), docId);
        // debugger
        const finalList = [...retrievedData?.list]
        // debugger;
        finalList.forEach((item) => {
            if (item.campaign_id == campaignSelected?.campaign_id) {
                item.payment = true;
                if (campaignSelected?.budget) {
                    item.passion_credits -= parseFloat(campaignSelected.budget.replace(/,/g, '')) || 0;
                }
            }
        })

        try {
            await updateData(localStorage.getItem("uname"), docId, { list: finalList });
            getDataForCampaigns(campaignSelected?.campaign_id)
            handleClosePaymentModal()
        }
        catch (error) {
            window.alert(error)
        }
    }

    function timeRemaining(acceptedTimestamp) {
        // Parse the timestamp (brand_accepted_timestamp) and get the current time
        const acceptedDate = new Date(acceptedTimestamp);
        const currentDate = new Date();
        acceptedDate.setHours(currentDate.getHours() + 24);

        // Calculate the difference in milliseconds
        const diffInMs = acceptedDate - currentDate;

        if (diffInMs < 0) return ''

        // Convert milliseconds to hours and minutes
        const hours = Math.floor(diffInMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        // Format as HH:MM
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return formattedTime;
    }

    return (
        <div className='container' style={{ maxWidth: "100%" }}>
            <div class="app">
                <header class="app-header">
                    <div class="app-header-logo">
                        <div class="logo">
                            {/* <span class="logo-icon"> */}
                            {/* <Orange size={32} color="#d62424" weight="duotone" /> */}

                            {/* <MetaLogo size={32} color="#245fd6" weight="bold" /> */}
                            {/* <img src="src/assets/images/nike_logo.png" /> */}
                            {/* </span> */}
                            <h1 class="logo-title">
                                <span>{companyDetails?.company_name ?? ""}</span>
                                {/* <span></span> */}
                            </h1>
                        </div>
                    </div>
                    <div class="app-header-navigation">
                        <div class="tabs">
                            <a href="#" class="active">
                                Your Campaigns
                            </a>
                            {/* <a href="#" >
                                Completed
                            </a> */}
                        </div>
                    </div>
                    <div class="app-header-actions">
                        <button class="user-profile">
                            <span>{companyDetails?.full_name ?? ""}</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#245fd6" viewBox="0 0 256 256"><path d="M160,96H96a52,52,0,0,0,0,104h64a52,52,0,0,0,0-104Zm0,80H96a28,28,0,0,1,0-56h64a28,28,0,0,1,0,56Zm-48-28a16,16,0,1,1-16-16A16,16,0,0,1,112,148Zm64,0a16,16,0,1,1-16-16A16,16,0,0,1,176,148ZM212,36a36,36,0,0,0-33.94,24H77.94A36,36,0,0,0,8,72v76a88.1,88.1,0,0,0,88,88h64a88.1,88.1,0,0,0,88-88V72A36,36,0,0,0,212,36Zm12,112a64.07,64.07,0,0,1-64,64H96a64.07,64.07,0,0,1-64-64V72a12,12,0,0,1,24,0A12,12,0,0,0,68,84H188a12,12,0,0,0,12-12,12,12,0,0,1,24,0Z"></path></svg>
                                {/* <img src="https://assets.codepen.io/285131/almeria-avatar.jpeg" /> */}
                            </span>
                        </button>
                        <div class="app-header-actions-buttons">
                            <button class="icon-button large">
                                <MagnifyingGlass />
                                {/* <i class="ph-magnifying-glass"></i> */}
                            </button>
                            <button class="icon-button large">
                                <Bell color="white" weight="duotone" />
                                {/* <i class="ph-bell"></i> */}
                            </button>
                            <button class="icon-button large">
                                <SignOut size={32} onClick={() => { localStorage.removeItem("uname"); navigate("/") }} />
                                {/* <i class="ph-bell"></i> */}
                            </button>
                        </div>
                    </div>
                    <div class="app-header-mobile">
                        <button class="icon-button large">
                            <i class="ph-list"></i>
                        </button>
                    </div>

                </header>
                <div class="app-body">
                    <div class="app-body-navigation">
                        <nav class="navigation">
                            <Link to={'/home'}>
                                <i class="ph-browsers"></i>
                                <span>Home</span>
                            </Link>
                            {/* <a href="#">
                                <i class="ph-check-square"></i>
                                <span>Reports</span>
                            </a> */}
                            <Link onClick={createNewCampaign}>
                                <i class="ph-swap"></i>
                                <span>Create Campaign</span>
                            </Link>
                            <Link to={"/onboard/companyname"}>
                                <i class="ph-swap"></i>
                                <span>Edit Brand Details</span>
                            </Link>
                            {/* <a href="#">
                                <i class="ph-file-text"></i>
                                <span>Influencer Search</span>
                            </a>
                            <a href="#">
                                <i class="ph-globe"></i>
                                <span>Campaign Analytics</span>
                            </a>
                            <a href="#">
                                <i class="ph-clipboard-text"></i>
                                <span>Settings</span>
                            </a>
                            <a href="#">
                                <i class="ph-clipboard-text"></i>
                                <span>Account</span>
                            </a> */}
                        </nav>
                        <footer class="footer">
                            <h1>PassionAI<small>©</small></h1>
                            <div
                            // onClick={filterInfluencers}
                            >
                                Beefluence ©<br />
                                All Rights Reserved 2024
                            </div>
                        </footer>
                    </div>
                    <div class="app-body-main-content">
                        <div class="bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 h-screen flex overflow-hidden text-sm">
                            {/* <div class="bg-white dark:bg-gray-900 dark:border-gray-800 w-20 flex-shrink-0 border-r border-gray-200 flex-col hidden sm:flex">
                                <div class="h-16 text-blue-500 flex items-center justify-center">
                                    <svg class="w-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 54 33">
                                        <path fill="currentColor" fill-rule="evenodd" d="M27 0c-7.2 0-11.7 3.6-13.5 10.8 2.7-3.6 5.85-4.95 9.45-4.05 2.054.513 3.522 2.004 5.147 3.653C30.744 13.09 33.808 16.2 40.5 16.2c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C36.756 3.11 33.692 0 27 0zM13.5 16.2C6.3 16.2 1.8 19.8 0 27c2.7-3.6 5.85-4.95 9.45-4.05 2.054.514 3.522 2.004 5.147 3.653C17.244 29.29 20.308 32.4 27 32.4c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C23.256 19.31 20.192 16.2 13.5 16.2z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div class="flex mx-auto flex-grow mt-4 flex-col text-gray-400 space-y-4">
                                    <button class="h-10 w-12 dark:text-gray-500 rounded-md flex items-center justify-center">
                                        <svg viewBox="0 0 24 24" class="h-5" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                    </button>
                                    <button class="h-10 w-12 dark:bg-gray-700 dark:text-white rounded-md flex items-center justify-center bg-blue-100 text-blue-500">
                                        <svg viewBox="0 0 24 24" class="h-5" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                        </svg>
                                    </button>
                                    <button class="h-10 w-12 dark:text-gray-500 rounded-md flex items-center justify-center">
                                        <svg viewBox="0 0 24 24" class="h-5" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                                            <line x1="12" y1="11" x2="12" y2="17"></line>
                                            <line x1="9" y1="14" x2="15" y2="14"></line>
                                        </svg>
                                    </button>
                                    <button class="h-10 w-12 dark:text-gray-500 rounded-md flex items-center justify-center">
                                        <svg viewBox="0 0 24 24" class="h-5" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="3" y="3" width="7" height="7"></rect>
                                            <rect x="14" y="3" width="7" height="7"></rect>
                                            <rect x="14" y="14" width="7" height="7"></rect>
                                            <rect x="3" y="14" width="7" height="7"></rect>
                                        </svg>
                                    </button>
                                </div>
                            </div> */}
                            <div class="flex-grow overflow-hidden h-full flex flex-col">
                                {/* <div class="h-16 lg:flex w-full border-b border-gray-200 dark:border-gray-800 hidden px-10">
                                    <div class="flex h-full text-gray-600 dark:text-gray-400">
                                        <a href="#" class="cursor-pointer h-full border-b-2 border-transparent inline-flex items-center mr-8">Company</a>
                                        <a href="#" class="cursor-pointer h-full border-b-2 border-blue-500 text-blue-500 dark:text-white dark:border-white inline-flex mr-8 items-center">Users</a>
                                        <a href="#" class="cursor-pointer h-full border-b-2 border-transparent inline-flex items-center mr-8">Expense Centres</a>
                                        <a href="#" class="cursor-pointer h-full border-b-2 border-transparent inline-flex items-center">Currency Exchange</a>
                                    </div>
                                    <div class="ml-auto flex items-center space-x-7">
                                        <button class="h-8 px-3 rounded-md shadow text-white bg-blue-500">Deposit</button>

                                        <button class="flex items-center">
                                            <span class="relative flex-shrink-0">
                                                <img class="w-7 h-7 rounded-full" src="https://images.unsplash.com/photo-1521587765099-8835e7201186?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ" alt="profile" />
                                                <span class="absolute right-0 -mb-0.5 bottom-0 w-2 h-2 rounded-full bg-green-500 border border-white dark:border-gray-900"></span>
                                            </span>
                                            <span class="ml-2">James Smith</span>
                                            <svg viewBox="0 0 24 24" class="w-4 ml-1 flex-shrink-0" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                </div> */}
                                <div class="flex-grow flex overflow-x-hidden">
                                    <div class="xl:w-72 w-48 flex-shrink-0 border-r border-gray-200 dark:border-gray-800 h-full overflow-y-auto lg:block hidden p-5" style={{ background: '#1f1f1f', width: "15rem" }}>
                                        <div class="text-xs text-gray-400 tracking-wider">LIVE CAMPAIGNS</div>
                                        <div class="relative mt-2">
                                            <input type="text" class="pl-8 h-9 bg-transparent border border-gray-300 dark:border-gray-700 dark:text-white w-full rounded-md text-sm" placeholder="Search" />
                                            <svg viewBox="0 0 24 24" class="w-4 absolute text-gray-400 top-1/2 transform translate-x-0.5 -translate-y-1/2 left-2" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg>
                                        </div>
                                        <div class="space-y-4 mt-3">
                                            {
                                                campaigns?.map((item, index) => {
                                                    if (item?.status !== "submitted") return (<></>)
                                                    return (
                                                        <button class={(item?.campaign_id == (campaignSelected?.campaign_id || campaignId)) ? "bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow-lg relative ring-2 ring-blue-500 focus:outline-none" : "bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow"}
                                                            style={{ background: index % 2 == 0 ? '#e3ffa8' : 'rgb(253, 228, 191)' }}
                                                            onClick={() => { setCampaignSelected(item); setTabSelected("selected"); getDataForCampaigns(item?.campaign_id) }}
                                                        // style={{ background: '#67ffc7' }}
                                                        >
                                                            {console.log(item?.campaign_id == (campaignSelected?.campaign_id || campaignId))}
                                                            <div class="flex xl:flex-row flex-col items-center font-medium text-gray-900 dark:text-white pb-2 mb-2  border-gray-200 border-opacity-75 dark:border-gray-700 w-full">
                                                                <Sneaker size={32} style={{ marginRight: '8px' }} />
                                                                <b>{item?.campaign_name || "-"}</b>
                                                            </div>
                                                            <div class="flex items-center w-full">
                                                                {/* <div class="text-xs py-1 px-2 leading-none dark:bg-gray-900 bg-blue-100 text-blue-500 rounded-md">UGC</div> */}
                                                                <div class="ml-auto text-xs text-gray-500">{item?.platform}</div>
                                                            </div>
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div class="flex-grow bg-white dark:bg-gray-900 overflow-y-auto" style={{ background: "#1f1f1f" }}>
                                        <div class="sm:px-7 sm:pt-7 px-4 pt-4 flex flex-col w-full border-b border-gray-200 bg-white dark:bg-gray-900 dark:text-white dark:border-gray-800 sticky top-0" style={{ background: '#1f1f1f' }}>
                                            <div class="flex w-full items-center">
                                                <div class="flex items-center text-3xl dark:text-white" style={{ justifyContent: "space-between", width: "100%" }}>
                                                    <div style={{ color: 'white', display: "flex" }}>
                                                        <Sneaker size={32} style={{ marginRight: '8px' }} />
                                                        {campaignSelected?.campaign_name || "-"}
                                                    </div>
                                                    <div style={{ color: "white" }}>
                                                        <p style={{ fontSize: "16px", lineHeight: "18px" }}>Expected Reach - <b style={{ color: "green", fontSize: "18px" }}>{formatToIndianLakhSystem(Math.ceil(totalReach))}</b></p>
                                                        <p style={{ fontSize: "16px", lineHeight: "18px" }}>Expected Engagement -<b style={{ color: "green", fontSize: "18px" }}> {(totalEngRate.toFixed(3) * 100) || ""}%</b></p>

                                                    </div>
                                                    <div>
                                                        {!campaignSelected?.brand_accepted_timestamp && <Button size='large' className='question-onboard__prv-btn'
                                                            style={{ fontSize: "16px", marginRight: "16px" }}
                                                            onClick={handleClickOpenCountModal}>
                                                            Filter
                                                        </Button>}
                                                        {!campaignSelected?.payment && <Button size='large' className='question-onboard__prv-btn'
                                                            style={{ fontSize: "16px", marginRight: "16px" }}
                                                            onClick={() => {
                                                                localStorage.setItem("current_campaign_id", campaignSelected?.campaign_id);
                                                                navigate('/campaign/objective')
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>}
                                                        {/* {console.log(campaignSelected)} */}
                                                        {campaignSelected?.payment && <Button size='large' className='question-onboard__prv-btn'
                                                            style={{ fontSize: "16px", marginRight: "16px" }}
                                                            onClick={sendRequest} disabled={campaignSelected?.brand_accepted_timestamp}>
                                                            {
                                                                campaignSelected?.brand_accepted_timestamp ?
                                                                    (timeRemaining(campaignSelected?.brand_accepted_timestamp) ?
                                                                        `Time Remaining - ${timeRemaining(campaignSelected?.brand_accepted_timestamp)}` :
                                                                        'Time Completed!') :
                                                                    "Send Request"
                                                            }
                                                        </Button>}
                                                        {!campaignSelected?.payment && <Button size='large' className='question-onboard__prv-btn'
                                                            style={{ fontSize: "16px" }}
                                                            onClick={handleClickOpenPaymentModal}>
                                                            Complete Payment
                                                        </Button>}
                                                    </div>
                                                </div>
                                                <div class="ml-auto sm:flex hidden items-center justify-end">
                                                </div>
                                            </div>
                                            <div class="flex items-center space-x-3 sm:mt-7 mt-4">
                                                {/* <a href="#" class="px-3 border-b-2 border-transparent text-gray-600 dark:text-gray-400 pb-1.5" style={{ color: 'white' }}>Applied</a> */}
                                                <a href="#" class="px-3 border-b-2 border-transparent text-gray-600 dark:text-gray-400 pb-1.5"
                                                    style={{ color: tabSelected === 'selected' ? 'rgb(67 137 251)' : 'white' }} onClick={() => { setTabSelected("selected"); getDataForCampaigns(campaignSelected?.campaign_id) }}>Recommended</a>
                                                <a href="#" class="px-3 border-b-2 border-transparent text-gray-600 dark:text-gray-400 pb-1.5 sm:block hidden"
                                                    style={{ color: tabSelected === 'review' ? 'rgb(67 137 251)' : 'white' }} onClick={() => { setTabSelected("review"); getDataForCampaigns(campaignSelected?.campaign_id) }}>Under Review</a>
                                                {/* <a href="#" class="px-3 border-b-2 border-transparent text-gray-600 dark:text-gray-400 pb-1.5 sm:block hidden" style={{ color: 'white' }}>Completed</a> */}
                                                <a href="#" class="px-3 border-b-2 border-transparent text-gray-600 dark:text-gray-400 pb-1.5 sm:block hidden"
                                                    style={{ color: tabSelected === 'live' ? 'rgb(67 137 251)' : 'white' }} onClick={() => { setTabSelected("live"); getDataForCampaigns(campaignSelected?.campaign_id) }}>Live</a>
                                            </div>
                                        </div>
                                        <div class="sm:p-7 p-4" style={{ background: 'inherit', height: '100vh', padding: "0.75rem" }}>
                                            <div class="flex w-full items-center mb-7">

                                            </div>
                                            {isLoading ? <Loader /> : <table class="w-full text-left">
                                                <thead >
                                                    <tr class="text-gray-400">
                                                        {tabSelected == "selected" && <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800">Request</th>}
                                                        <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800">Full Name</th>
                                                        <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800">User Name</th>
                                                        {/* <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800">Gender</th> */}
                                                        <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800 hidden md:table-cell">Followers/Subs</th>
                                                        <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800">{tabSelected == "selected" ? "Avg Reach" : tabSelected == "review" ? "Review Link" : "Live Link"}</th>
                                                        <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800 sm:text-gray-400 text-white">Passion Score</th>
                                                        {tabSelected == "review" && <th class="font-normal px-3 pt-0 pb-3 border-b border-gray-200 dark:border-gray-800 sm:text-gray-400 text-white">Accept/Reject</th>}
                                                    </tr>
                                                </thead>
                                                <tbody class="text-gray-600 dark:text-gray-100" style={{ textAlign: 'left' }}>
                                                    {!campaignSelected?.selected?.length ? <></> : campaignSelected?.selected?.filter((item) => {
                                                        if (tabSelected === 'review') {
                                                            return item?.brand_accepted == true &&
                                                                item?.user_accepted == true && !item?.live_link
                                                        }
                                                        if (tabSelected === "live") {
                                                            return item?.live_link
                                                        }
                                                        if (tabSelected === "selected") {
                                                            return !item?.user_accepted
                                                        }
                                                    })?.map((item) => {
                                                        // if (item?.visible == false) return <></>
                                                        return (
                                                            <tr style={{ color: 'whitesmoke' }}>
                                                                {
                                                                    tabSelected == "selected" &&
                                                                    <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800 shorter-length">
                                                                        <div class="flex items-center"
                                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                                            <input type='checkbox' className='selection-check-box'
                                                                                disabled={campaignSelected?.brand_accepted_timestamp}
                                                                                checked={selectedList?.includes(item?.username)}
                                                                                onChange={(e) => listHandler(e.target.checked, item)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                }
                                                                <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800">
                                                                    <div class="flex items-center"
                                                                        onClick={() => handleOpen(item)}
                                                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                                        {campaignSelected?.payment == true ? item?.name : "****"}
                                                                    </div>
                                                                </td>
                                                                <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800">
                                                                    <div class="flex items-center"
                                                                        onClick={() => {
                                                                            campaignSelected?.payment == true &&
                                                                                window.open(`https://www.instagram.com/${item?.username}/`, '_blank')
                                                                        }}
                                                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                                        {campaignSelected?.payment == true ? item?.username : "****"}
                                                                    </div>
                                                                </td>
                                                                <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800 md:table-cell hidden">
                                                                    {formatToIndianLakhSystem(item?.followers)}
                                                                </td>
                                                                <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800 text-green-500">
                                                                    {
                                                                        tabSelected == "selected" ? formatToIndianLakhSystem(item?.avg_reach) : <a style={{ textDecoration: "underline" }} href={tabSelected == "selected" ? item?.link : item?.live_link} target='_blank' onClick={(event) => { openVideo(event, item?.link) }}>Link</a>
                                                                    }
                                                                </td>
                                                                <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800">
                                                                    <div class="flex items-center">
                                                                        <div class="sm:flex hidden flex-col">
                                                                            {item?.passion_score}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    tabSelected === 'review' &&
                                                                    <td class="sm:p-3 py-2 px-1 border-b border-gray-200 dark:border-gray-800 text-green-500">
                                                                        {item?.draft_accepted ? <div>Content Accepted!</div> : <div class="action-button-container">
                                                                            <button class="action-btn tick-btn"
                                                                                onClick={() => { updateDraftStatus(item, true) }}>
                                                                                &#10003;
                                                                            </button>
                                                                            <button class="action-btn cross-btn"
                                                                                onClick={() => {
                                                                                    handleClickOpenCommentModal();
                                                                                    localStorage.setItem('rejection_item', JSON.stringify(item))
                                                                                }}>
                                                                                &#10005;
                                                                            </button>
                                                                        </div>}
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <AnalyticsModal
                open={open}
                campaignSelected={campaignSelected}
                data={selectedUserForQuickView}
                handleOpen={handleOpen}
                handleClose={handleClose} />

            <CountModal
                open={openCountModal}
                handleOpen={handleClickOpenCountModal}
                handleClose={handleCloseCountModal}
                customCount={customCount}
                setCustomCount={setCustomCount}
                onCustomClick={onCustomClick}
                onDefaultClick={onDefaultClick}
                defaultCount={campaignSelected?.[tabSelected]?.length}
            />

            <CommentModal
                open={openCommentModal}
                handleClose={handleCloseCommentModal}
                value={rejectionComment}
                setValue={setRejectionComment}
                onSubmit={onCommentSubmit}
            />

            <PaymentModal
                open={openPaymenttModal}
                handleClose={handleClosePaymentModal}
                onPay={onPay}
                campaignSelected={campaignSelected}
            />
        </div>

    )
}

export default CampaignTableNew



// top 10 select --done test?
// quick view --done
// initial error on FE --check?
// add objective to BE --done
// add micro etc to BE --important --done
// under review mai reject mai pop up for a comment which will go in DB --UI fix
// review link + update key(version count)  -- need to write a script for this for now
// and UI should have blue text to see version as well --left
// comment array of objects with text + version keys --done
// live link aate he event trigger to shift to live tab --done test?
// put credit amount in FB --done
// no change in exp reach etc when clicking chewck box