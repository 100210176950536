import { Button, Flex, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { checkDocumentExists, getData, updateData } from '../../utils/saveData';

function Budget() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";
    const docIdBrand = 'brand_details';

    const [value, setValue] = useState(null);
    const [name, setName] = useState("");

    useEffect(() => {
        const load = async () => {
            // for that id we will check if budget is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.budget) {
                            setValue(currentCampaign?.budget)
                        }
                    }
                    else {
                        // go back
                    }
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.campaign_name) {
                            setName(currentCampaign?.campaign_name)
                        }
                        else {
                            const retrievedDataBrand = await getData(collectionName, docIdBrand);
                            if (retrievedDataBrand) {
                                const companyName = retrievedDataBrand?.company_name;
                                if (companyName) {
                                    setName(`${companyName.toUpperCase().slice(0, 3)}${localStorage.getItem('current_campaign_id')}`)
                                }
                            }
                        }
                    }
                    else {
                        const retrievedDataBrand = await getData(collectionName, docIdBrand);
                        if (retrievedDataBrand) {
                            const companyName = retrievedDataBrand?.company_name;
                            if (companyName) {
                                setName(`${companyName.toUpperCase().slice(0, 3)}${localStorage.getItem('current_campaign_id')}`)
                            }
                        }
                    }

                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load()
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter the budget!");
            return;
        }

        if (name.trim().length > 20) {
            alert("Campaign Name can't be more than 20 characters!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.budget = value;
                item.campaign_name = name;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/timeline')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter the budget!");
            return;
        }

        if (name.trim().length > 20) {
            alert("Campaign Name can't be more than 20 characters!");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.budget = value;
                item.campaign_name = name;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/campaign/objective')
    }

    // Function to format the number as per the Indian numbering system
    const formatToIndianNumberingSystem = (num) => {
        // Remove all commas before formatting
        let x = num.replace(/,/g, '');

        // Handle numbers less than or equal to 3 digits (no commas)
        if (x.length <= 3) {
            return x;
        }

        // Handle numbers greater than 3 digits, format according to lakhs system
        let lastThree = x.slice(-3); // Last 3 digits
        let otherNumbers = x.slice(0, x.length - 3); // Digits before the last 3

        // Add comma after every 2 digits in the remaining number
        if (otherNumbers !== '') {
            otherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
        }

        return otherNumbers + ',' + lastThree;
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const numberOnly = inputValue.replace(/[^0-9]/g, ""); // Allow only numeric input
        const formattedValue = formatToIndianNumberingSystem(numberOnly);
        setValue(formattedValue);
    };

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title'>Campaign Name</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <Input placeholder="" value={name} onChange={(e) => { setName(e.target.value) }} />
                </div>
            </div>
            <div className='question__header'>
                <h1 className='question__title'>Enter your estimated campaign budget</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <Input placeholder="Enter the amount in &#8377;" value={value} onChange={handleChange} />
                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default Budget