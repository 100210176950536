import { Button, Flex, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { checkDocumentExists, getData, saveData, updateData } from '../../utils/saveData';
import dayjs from 'dayjs';
import moment from 'moment';

const { RangePicker } = DatePicker;

function TimeLine() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [value, setValue] = useState(null);

    useEffect(() => {
        const load = async () => {
            // for that id we will check if timeline is there, if so then populate
            const current_campaign_id = localStorage.getItem("current_campaign_id");
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.list?.length) {
                        const currentCampaign = retrievedData?.list.find((item) => { return item.campaign_id == current_campaign_id });
                        if (currentCampaign?.timeline) {
                            setValue([dayjs(currentCampaign?.timeline?.[0]), dayjs(currentCampaign?.timeline?.[1])])
                        }
                    }
                    else {
                        // go back
                    }
                };
                fetchData();
            }
            else {
                // handle?
            }
        }
        load();
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter the timeline!");
            return;
        }
        
        const startdate = moment(value?.[0]?.$d)
        const enddate = moment(value?.[0]?.$d)
        const today = moment();
        if (startdate.isBefore(today, 'day') || enddate.isBefore(today, 'day')) {
            alert("Start and End date can not be less than todays date.");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.timeline = [moment(value?.[0]?.$d).format("YYYY-MM-DD"), moment(value?.[1]?.$d).format("YYYY-MM-DD")];
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            navigate('/campaign/platform')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {
        if (!value) {
            alert("Enter the timeline!");
            return;
        }

        const startdate = moment(value?.[0]?.$d)
        const enddate = moment(value?.[0]?.$d)
        const today = moment();
        if (startdate.isBefore(today, 'day') || enddate.isBefore(today, 'day')) {
            alert("Start and End date can not be less than todays date.");
            return;
        }

        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        finalList.forEach((item) => {
            if (item.campaign_id == localStorage.getItem("current_campaign_id")) {
                item.timeline = [moment(value?.[0]?.$d).format("YYYY-MM-DD"), moment(value?.[1]?.$d).format("YYYY-MM-DD")];
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Saved!");
            navigate('/home')
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/campaign/timeline')
    }


    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };

    return (
        <Flex className='question__container' vertical justify='space-between'>
            <div className='question__header'>
                <h1 className='question__title'>Enter the estimated campaign timelines</h1>
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question__field'>
                    <RangePicker style={{ color: 'white' }} ty value={value}
                        onChange={(e) => setValue(e)}
                        disabledDate={disabledDate} />
                </div>
            </div>
            <div className='question__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question__next-btn' onClick={onSave}>Save & Exit</Button>
                        <Button size='large' className='question__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default TimeLine
