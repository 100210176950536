import React, { useEffect, useState } from 'react'
import { Button, Flex, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { checkDocumentExists, getData, saveData, updateData } from '../../utils/saveData';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

function FullName() {
    const navigate = useNavigate();
    const regex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "brand_details";

    const [value, setValue] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        const load = async () => {
            const res = await checkDocumentExists(collectionName, docId)
            if (res) {
                const fetchData = async () => {
                    const retrievedData = await getData(collectionName, docId);
                    if (retrievedData?.full_name) {
                        setValue(retrievedData?.full_name)
                    }
                    if (retrievedData?.phone_number) {
                        setPhone(retrievedData?.phone_number)
                    }
                };
                fetchData();
            }
            else {
                // If doc is not there then it means that 1st question was never saved/submitted
                navigate('/onboard/companyname');
            }
        }
        load();
    }, []);

    const onNext = async () => {

        if (!value) {
            alert("Enter full name.");
            return;
        }

        if (!regex.test(value)) {
            alert("Invalid full name.");
            return;
        }

        // const updatedData = { full_name: value };
        // try {
        //     const res = await checkDocumentExists(collectionName, docId)
        //     if (res) {
        //         // update if the user clicks previous from any screen
        //         await updateData(collectionName, docId, updatedData);
        //         navigate('/onboard/phonenumber')

        //     }
        //     else {
        //         // as this is first page, the document must be created if it is the first save
        //         await saveData(collectionName, docId, { full_name: value });
        //         navigate('/onboard/phonenumber')

        //     }
        // }
        // catch (error) {
        //     window.alert(error)
        // }
        const updatedData = { full_name: value, phone_number: phone };
        try {
            await updateData(collectionName, docId, updatedData);
            navigate('/onboard/role')

        }
        catch (error) {
            window.alert(error)
        }
    }

    const onSave = async () => {

        if (!value) {
            alert("Enter full name.");
            return;
        }

        if (!regex.test(value)) {
            alert("Invalid full name.");
            return;
        }

        // const updatedData = { full_name: value };
        // const res = await checkDocumentExists(collectionName, docId)
        // if (res) {
        //     // console.log(collectionName, docId, checkDocumentExists(collectionName, docId))
        //     // update if the user clicks previous from any screen
        //     await updateData(collectionName, docId, updatedData);
        //     window.alert("Saved!");

        // }
        // else {
        //     console.log("bye")
        //     // as this is first page, the document must be created if it is the first save
        //     await saveData(collectionName, docId, { full_name: value });
        //     window.alert("Saved!");
        // }
        const updatedData = { full_name: value, phone_number: phone };
        try {
            await updateData(collectionName, docId, updatedData);
            window.alert("Saved!");
        }
        catch (error) {
            window.alert(error)
        }
    }

    const onPrevious = () => {
        navigate('/onboard/companyname')
    }

    return (
        <Flex className='question-onboard__container' vertical justify='space-between'>
            <div className='question-onboard__header'>
                {/* <h1 className='question-onboard__title question-onboard__title--required'>Enter your full name</h1> */}
                {/* <h4>Tell us about the products you sell</h4> */}
                <div className='question-onboard__field'>
                    <Input placeholder="*Enter your full name" value={value} onChange={(e) => { setValue(e.target.value) }} />
                </div>
                <div className='question-onboard__field'>
                    <PhoneInput
                        placeholder="Enter your phone number"
                        value={phone}
                        defaultCountry='IN'
                        onChange={setPhone} />
                    {/* <Button>Send OTP</Button> */}
                </div>
            </div>
            <div className='question-onboard__footer'>
                <Flex vertical={false} justify='space-between'>
                    <div>
                        <Button size='large' className='question-onboard__prv-btn' onClick={onPrevious}>&larr; Previous</Button>
                    </div>
                    <div>
                        <Button size='large' className='question-onboard__save-btn' type='primary' onClick={onSave}>Save</Button>
                        <Button size='large' className='question-onboard__next-btn' onClick={onNext}>Next &rarr;</Button>
                    </div>
                </Flex>
            </div>
        </Flex>
    )
}

export default FullName