import React, { useEffect, useState } from 'react';
import { MagnifyingGlass, Bell, TShirt, SneakerMove, Sneaker, ArrowRight, Orange, SignOut, MetaLogo, TrendUp, User, Funnel, Trash } from "@phosphor-icons/react";
import { Link, useNavigate } from 'react-router-dom';
import { getData, saveData, updateData } from '../../utils/saveData';
import { DatePicker } from 'antd';

function Home() {
    const navigate = useNavigate();

    let collectionName;
    if (localStorage.getItem("uname")) {
        collectionName = localStorage.getItem("uname");
    }
    else {
        navigate('/login');
    }

    const docId = "campaign_details";

    const [campaigns, setCampaigns] = useState([])
    const [companyDetails, setCompanyDetails] = useState({})
    const [open, setOpen] = useState(false);

    const handleOpenChange = (status) => {
        setOpen(status);
    };

    const handleButtonClick = () => {
        setOpen(true);
    };


    useEffect(() => {
        getDataForCampaigns()
    }, [])

    const getDataForCampaigns = async () => {
        const retrievedData = await getData(collectionName, docId);
        if (retrievedData?.list?.length) {
            const list = retrievedData?.list;
            // .filter((data) => { return !data?.is_inactive })
            setCampaigns(list)
        }
        else {
            setCampaigns([])
        }


        const brandData = await getData(collectionName, "brand_details");
        setCompanyDetails(brandData)
    }

    const createNewCampaign = async () => {
        // It is assumed that no campaigns will be deleted once created it will remain part of the list, It can be in edit mode or submitted mode only.
        const list = [...campaigns];
        const id = list.length + 1
        list.push({
            campaign_id: id,
            status: "edit"
        })
        await saveData(collectionName, docId, { list });
        localStorage.setItem("current_campaign_id", id);
        navigate('/campaign/objective')

    }

    const onCampaignDelete = async (e, data) => {
        e.preventDefault();

        if (!window.confirm('Do you want to delete this campaign draft?')) return;
        const retrievedData = await getData(collectionName, docId);
        const finalList = [...retrievedData?.list]
        // const deletedList = finalList.filter((data) => { return data.campaign_id !== item.campaign_id })
        // debugger
        finalList.forEach((item) => {
            if (item.campaign_id == data.campaign_id) {
                item.is_inactive = true;
            }
        })

        try {
            await updateData(collectionName, docId, { list: finalList });
            window.alert("Campaign successfully deleted!");
            getDataForCampaigns();
            // navigate('/campaign/timeline')
        }
        catch (error) {
            window.alert(error)
        }
    }

    return (
        <div className='container' style={{ maxWidth: "100%" }}>
            <div class="app">
                <header class="app-header">
                    <div class="app-header-logo">
                        <div class="logo">
                            {/* <span class="logo-icon"> */}
                            {/* <Orange size={32} color="#d62424" weight="duotone" /> */}

                            {/* <MetaLogo size={32} color="#245fd6" weight="bold" /> */}
                            {/* <img src="src/assets/images/nike_logo.png" /> */}
                            {/* </span> */}
                            <h1 class="logo-title">
                                <span>{companyDetails?.company_name ?? ""}</span>
                                {/* <span></span> */}
                            </h1>
                        </div>
                    </div>
                    <div class="app-header-navigation">
                        <div class="tabs">
                            <a href="#" class="active">
                                Passion AI Campaigns
                            </a>
                            {/* <a href="#" >
                                USA
                            </a> */}
                            {/* <a href="#">
                            Cards
                        </a> */}
                            {/* <a href="#">
                            
                        </a>
                        <a href="#">
                            
                        </a>
                        <a href="#">
                            
                        </a> */}
                        </div>
                    </div>
                    <div class="app-header-actions">
                        <button class="user-profile">
                            <span>{companyDetails?.full_name ?? ""}</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#245fd6" viewBox="0 0 256 256"><path d="M160,96H96a52,52,0,0,0,0,104h64a52,52,0,0,0,0-104Zm0,80H96a28,28,0,0,1,0-56h64a28,28,0,0,1,0,56Zm-48-28a16,16,0,1,1-16-16A16,16,0,0,1,112,148Zm64,0a16,16,0,1,1-16-16A16,16,0,0,1,176,148ZM212,36a36,36,0,0,0-33.94,24H77.94A36,36,0,0,0,8,72v76a88.1,88.1,0,0,0,88,88h64a88.1,88.1,0,0,0,88-88V72A36,36,0,0,0,212,36Zm12,112a64.07,64.07,0,0,1-64,64H96a64.07,64.07,0,0,1-64-64V72a12,12,0,0,1,24,0A12,12,0,0,0,68,84H188a12,12,0,0,0,12-12,12,12,0,0,1,24,0Z"></path></svg>
                                {/* <img src="https://assets.codepen.io/285131/almeria-avatar.jpeg" /> */}
                            </span>
                        </button>
                        <div class="app-header-actions-buttons">
                            <button class="icon-button large">
                                <MagnifyingGlass />
                                {/* <i class="ph-magnifying-glass"></i> */}
                            </button>
                            <button class="icon-button large">
                                <Bell color="white" weight="duotone" />
                                {/* <i class="ph-bell"></i> */}
                            </button>
                            <button class="icon-button large">
                                <SignOut size={32} onClick={() => { localStorage.removeItem("uname"); navigate("/") }} />
                                {/* <i class="ph-bell"></i> */}
                            </button>
                        </div>
                    </div>
                    <div class="app-header-mobile">
                        <button class="icon-button large">
                            <i class="ph-list"></i>
                        </button>
                    </div>

                </header>
                <div class="app-body">
                    <div class="app-body-navigation">
                        <nav class="navigation">
                            <a href="#">
                                <i class="ph-browsers"></i>
                                <span>Home</span>
                            </a>
                            {/* <a href="#">
                                <i class="ph-check-square"></i>
                                <span>Reports</span>
                            </a> */}
                            <Link onClick={createNewCampaign}>
                                <i class="ph-swap"></i>
                                <span>Create Campaign</span>
                            </Link>
                            <Link to={"/onboard/companyname"}>
                                <i class="ph-swap"></i>
                                <span>Edit Brand Details</span>
                            </Link>
                            {/* <a href="#">
                                <i class="ph-file-text"></i>
                                <span>Influencer Search</span>
                            </a>
                            <a href="#">
                                <i class="ph-globe"></i>
                                <span>Campaign Analytics</span>
                            </a>
                            <a href="#">
                                <i class="ph-clipboard-text"></i>
                                <span>Settings</span>
                            </a>
                            <a href="#">
                                <i class="ph-clipboard-text"></i>
                                <span>Account</span>
                            </a> */}
                        </nav>
                        <footer class="footer">
                            <h1>PassionAI<small>©</small></h1>
                            <div>
                                Beefluence ©<br />
                                All Rights Reserved 2024
                            </div>
                        </footer>
                    </div>
                    <div class="app-body-main-content">
                        <section class="service-section">
                            <h2>Pending Campaigns</h2>
                            <div class="service-section-header">
                                <div class="search-field">
                                    <div class="find-icon">
                                        <MagnifyingGlass />
                                    </div>
                                    <input type="text" placeholder="Campaign Name" />
                                </div>
                                <button class="flat-button">
                                    Search
                                </button>
                                <button class="flat-button" style={{ display: 'flex' }}>
                                    <Funnel size={18} color="white" />
                                    <p style={{ marginLeft: '4px' }}>Date</p>
                                </button>
                            </div>
                            <div class="mobile-only">
                                <button class="flat-button">
                                    Toggle search
                                </button>
                            </div>
                            <div class="tiles">
                                {
                                    campaigns?.filter((data) => { return !data?.is_inactive })?.map((item) => {
                                        if (item?.status !== "edit") return (<></>)
                                        return (
                                            <article class="tile">
                                                <div class="tile-header">
                                                    <Sneaker size={32} />
                                                    <h3>
                                                        <span>{item?.campaign_name || "-"}</span>
                                                        <span class="location">&#8377;{item.budget}</span>
                                                        <span>{item?.objective && (item?.objective.join(",") || "-")}</span>
                                                    </h3>
                                                </div>
                                                <div className='actions'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}
                                                        onClick={() => {
                                                            // debugger;
                                                            localStorage.setItem("current_campaign_id", item?.campaign_id);
                                                            navigate('/campaign/objective')
                                                        }}>
                                                        <span style={{ marginRight: '8px' }}>Go to campaign</span>
                                                        <span class="icon-button">
                                                            <ArrowRight size={24} />
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {/* <span>Go to campaign</span> */}
                                                        <span class="">
                                                            <Trash size={24} color="#ed2c2c"
                                                                onClick={(e) => { onCampaignDelete(e, item) }}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </article>
                                        )
                                    })
                                }
                                {/* <article class="tile">
                                    <div class="tile-header">
                                        <SneakerMove size={32} />
                                        <h3>
                                            <span>Nike Air Jordans</span>
                                            <span class="location">India</span>
                                            <span>#NIK235</span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article>
                                <article class="tile">
                                    <div class="tile-header">
                                        <TShirt size={32} />
                                        <h3>
                                            <span>Nike Summer Wear</span>
                                            <span class="location">India, USA</span>
                                            <span>#NIK234</span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article> */}
                            </div>
                            {/* <div class="service-section-footer">
                                <p>View All</p>
                            </div> */}
                        </section>
                        <section class="service-section">
                            <br />
                            <h2>Live Campaigns</h2>
                            <div class="service-section-header">
                                <div class="search-field">
                                    <div class="find-icon">
                                        <MagnifyingGlass />
                                    </div>
                                    <input type="text" placeholder="Campaign Name" />
                                </div>
                                <button class="flat-button">
                                    Search
                                </button>
                                <button class="flat-button" style={{ display: 'flex' }}>
                                    <Funnel size={18} color="white" />
                                    <p style={{ marginLeft: '4px' }} onClick={handleButtonClick}>Date</p>
                                    {/* <DatePicker
                                        open={open}
                                        onOpenChange={handleOpenChange}
                                        onChange={() => setOpen(false)} // Close picker after a date is selected
                                    /> */}
                                </button>

                            </div>
                            <div class="mobile-only">
                                <button class="flat-button">
                                    Toggle search
                                </button>
                            </div>
                            <div class="tiles">
                                {
                                    campaigns?.filter((data) => { return !data?.is_inactive })?.map((item) => {
                                        if (item?.status !== "submitted") return (<></>)
                                        return (
                                            <article class="tile" onClick={() => { navigate(`/campaign-detail-new?id=${item?.campaign_id}`) }}>
                                                <div class="tile-header">
                                                    <Sneaker size={32} />
                                                    <h3>
                                                        <span>{item?.campaign_name || "-"}</span>
                                                        <span class="location">&#8377;{item.budget}</span>
                                                        <span>{item?.objective && (item?.objective?.join(",") || "-")}</span>
                                                    </h3>
                                                </div>

                                                <a href="#">
                                                    <span>Go to campaign</span>
                                                    <span class="icon-button">
                                                        <ArrowRight size={24} />
                                                    </span>
                                                </a>
                                            </article>
                                        )
                                    })
                                }
                                {/* <article class="tile">
                                    <div class="tile-header">
                                        <Sneaker size={32} />
                                        <h3>
                                            <span>Nike Air Force 1</span>
                                            <span class="location">Delhi, Mumbai</span>
                                            <span>#NIK236</span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article> */}
                                {/* <article class="tile">
                                    <div class="tile-header">
                                        <SneakerMove size={32} />
                                        <h3>
                                            <span>Nike Air Jordans</span>
                                            <span class="location">India</span>
                                            <span>#NIK235</span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article> */}
                            </div>
                            {/* <div class="service-section-footer">
                                <p>View All</p>
                            </div> */}
                        </section>
                        <section class="service-section">
                            <br />
                            <h2>Completed Campaigns</h2>
                            {/* <div class="service-section-header">
                                <div class="search-field">
                                    <div class="find-icon">
                                        <MagnifyingGlass />
                                    </div>
                                    <input type="text" placeholder="Campaign Name" />
                                </div>
                                <button class="flat-button">
                                    Search
                                </button>
                            </div> */}
                            {/* <div class="mobile-only">
                                <button class="flat-button">
                                    Toggle search
                                </button>
                            </div> */}
                            {/* <div class="tiles">
                                <article class="tile">
                                    <div class="tile-header">
                                        <Sneaker size={32} />
                                        <h3>
                                            <span>Nike Air Force 1</span>
                                            <span class="location">Delhi, Mumbai</span>
                                            <span>#NIK226</span>
                                            <span style={{ marginTop: "2px" }}>
                                                <TrendUp color='green' size={18} style={{ marginRight: "2px" }} weight='bold' />2.3M
                                                <p style={{ margin: "0px 4px" }}>|</p>
                                                <User color="#245fd6" size={18} style={{ marginRight: "2px" }} weight='bold' />20
                                            </span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article>
                                <article class="tile">
                                    <div class="tile-header">
                                        <SneakerMove size={32} />
                                        <h3>
                                            <span>Nike Air Jordans</span>
                                            <span class="location">India</span>
                                            <span>#NIK221</span>
                                            <span style={{ marginTop: "2px" }}>
                                                <TrendUp color='green' size={18} style={{ marginRight: "2px" }} weight='bold' />3.9M
                                                <p style={{ margin: "0px 4px" }}>|</p>
                                                <User color="#245fd6" size={18} style={{ marginRight: "2px" }} weight='bold' />35
                                            </span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article>
                                <article class="tile">
                                    <div class="tile-header">
                                        <TShirt size={32} />
                                        <h3>
                                            <span>Nike Summer Wear</span>
                                            <span class="location">India, USA</span>
                                            <span>#NIK220</span>
                                            <span style={{ marginTop: "2px" }}>
                                                <TrendUp color='green' size={18} style={{ marginRight: "2px" }} weight='bold' />19.3M
                                                <p style={{ margin: "0px 4px" }}>|</p>
                                                <User color="#245fd6" size={18} style={{ marginRight: "2px" }} weight='bold' />210
                                            </span>
                                        </h3>
                                    </div>
                                    <a href="#">
                                        <span>Go to campaign</span>
                                        <span class="icon-button">
                                            <ArrowRight size={24} />
                                        </span>
                                    </a>
                                </article>
                            </div>
                            <div class="service-section-footer">
                                <p>View All</p>
                            </div> */}
                        </section>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default Home;